import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { Table } from '@/components/tables/table'
import { TableBubble } from '@/components/layout/tableBubble'
import { Text } from '@/components/general/text'
import { Account, Posting } from '@/services/reconciliation'
import React, { useMemo, useState } from 'react'
import { AccountDetails, Date } from '../common'
import { numberWithCurrency } from '@/utils'
import { getAccount } from '../../helpers'
import { Spacer } from '@/components/layout/spacer'
import { Flex } from '@/components/layout/flex'
import { UnreconcileAction } from './unreconcile-action'
import styled from 'styled-components'
import { SimpleButton } from '@/components/buttons/simpleButton'
import { getPostingCols } from '../postings/postings-table/helpers'

interface SinglePostingSectionProps {
    posting: Posting
    accounts: Account[]
}

export const SinglePostingSection: React.FC<SinglePostingSectionProps> = (props) => {
    const { posting, accounts } = props
    const [showRawData, setShowRawData] = useState(false)

    const cols = getPostingCols()

    const rows = useMemo(() => {
        return [{
            type: 'normal' as const,
            key: posting.id,
            noAction: true,
            items: [
                {
                    node: accounts && <AccountDetails account={getAccount(posting.account_id, accounts)} />
                },
                {
                    node: <Date>{posting.posting_date}</Date>
                },
                {
                    node: <Text>{posting.serial}</Text>
                },
                {
                    node: <Text>{posting.description}</Text>
                },
                {
                    node: <Text>{posting.reference}</Text>
                },
                {
                    node: <Text>{posting.counterparty_name}</Text>
                },
                {
                    node: <Text>{posting.counterparty_account}</Text>
                },
                {
                    node: <Text>{posting.posting_type}</Text>
                },
                {
                    node: (
                        <TableBubble type="important">
                            {numberWithCurrency(posting.currency, `${posting.amount}`)} {posting.currency}
                        </TableBubble>
                    )
                }
            ]
        }]
    }, [posting])

    return (
        <Card>
            <CardInset>
                <Table
                    loaderRows={1}
                    rows={rows}
                    cols={cols}
                    background="front.background"
                    noHighlights
                    columnLayout="min-content 100px 1fr 2fr auto 1fr 1fr 0.5fr min-content"
                    emptyText="No posting found."
                />
                <Spacer width={20} />
                <Flex align="flex-start" column>
                    <SimpleButton
                        color="side.accent.color"
                        onClick={() => {
                            setShowRawData((t) => !t)
                        }}
                    >
                        {showRawData ? 'Hide bank data' : 'Show bank data'}
                    </SimpleButton>
                </Flex>
                {showRawData && (
                    <>
                        <Spacer height={10} />
                        <RawBlock>
                            <CardInset type="small">
                                <Text mono>
                                    {posting.raw_type === "JSON" ?
                                        JSON.stringify(posting.raw, null, 4)
                                    :
                                        posting.raw
                                    }
                                </Text>
                            </CardInset>
                        </RawBlock>
                    </>
                )}
                
                {posting.reconciled && (
                    <>
                    <Spacer width={20} />
                    <Flex align="flex-end" column>
                        <UnreconcileAction type="posting" />
                    </Flex>
                    </>
                )}
            </CardInset>
        </Card>
    )
}

const RawBlock = styled.div`
border-radius: 10px;
background-color: ${(p) => p.theme['front.highlights']};
white-space: pre;
`
