import { VariantProps, cva } from 'class-variance-authority'

export type ContentStyles = VariantProps<typeof styles.content>

export const styles = {
    content: cva([
        'w-full',
        'z-[2000000]',
        'rounded-md',
        'bg-white',
        'p-4',
        'text-black',
        'shadow-md',
        'outline-none',
        'dark:bg-gray-900'
    ])
}
