import { all, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'

import { ApplicationInternalsAgentsActions } from './applicationInternals/agents/actions'
import { ApplicationInternalsAgentsSagas } from './applicationInternals/agents/sagas'
import { ApplicationInternalsChecksActions } from './applicationInternals/checks/actions'
import { ApplicationInternalsSagas } from './applicationInternals/checks/sagas'
import { ApplicationInternalsCollaboratorsActions } from './applicationInternals/collaborators/actions'
import { ApplicationInternalsCollaboratorsSagas } from './applicationInternals/collaborators/sagas'
import { ApplicationInternalsDetailsActions } from './applicationInternals/details/actions'
import { ApplicationInternalsDetailsSagas } from './applicationInternals/details/sagas'
import { ApplicationInternalsReferralActions } from './applicationInternals/referral/actions'
import { ApplicationInternalsReferralSagas } from './applicationInternals/referral/sagas'
import { ApplicationInternalsRelatedActions } from './applicationInternals/related/actions'
import { ApplicationInternalsRelatedSagas } from './applicationInternals/related/sagas'
import { ApplicationInternalsNeedingAttentionSagas } from './applicationInternals/needingAttention/sagas'
import { ApplicationInternalsTagsActions } from './applicationInternals/tags/actions'
import { ApplicationInternalsTagsSagas } from './applicationInternals/tags/sagas'
import { InterfaceSagas } from './interface/sagas'
import { ApplicationInternalsWarningsActions } from './applicationInternals/warning/actions'
import { ApplicationInternalsWarningsSagas } from './applicationInternals/warning/sagas'
import { ApplicationResourceActions } from './applicationResources/actions'
import { ApplicationResourceSagas } from './applicationResources/sagas'
import { VouchersSagas } from './manage/vouchers/sagas'
import {
    APPLICATIONS_APPROVE_DRAFT_AGREEMENT,
    APPLICATIONS_CLEANUP,
    APPLICATIONS_CREATE_DRAFT_AGREEMENT,
    APPLICATIONS_EDIT_ENTITY,
    APPLICATIONS_FETCH_CUTTER_INFO,
    APPLICATIONS_LOAD_APPLICATION,
    APPLICATIONS_LOAD_BVD_INFO,
    APPLICATIONS_LOAD_CVR_INFO,
    APPLICATIONS_LOAD_ENTRIES,
    APPLICATIONS_MARK_COMMENT_AS_READ,
    APPLICATIONS_REFUSE_DRAFT_AGREEMENT,
    APPLICATIONS_REMOVE_INTERNAL_COMMENT,
    APPLICATIONS_SEND_INTERNAL_COMMENT,
    APPLICATIONS_STORE_APPLICATION,
    APPLICATIONS_STORE_CUTTER_COMMENTS_INFO,
    APPLICATIONS_STORE_INTERNAL_COMMENT,
    APPLICATIONS_STORE_READ_COMMENT,
    APPLICATIONS_UPDATE_CUTTER_COMMENTS_INFO,
    APPLICATIONS_UPDATE_DRAFT_AGREEMENT
} from './applications/actions'
import { ApplicationsSagas } from './applications/sagas'
import { AUTH_INIT, AUTH_LOGIN, AUTH_LOGOUT, AUTH_STORE_USER } from './auth/actions'
import { AuthSagas } from './auth/sagas'
import {
    AUTOCOMPLETE_FETCH_SELECTED_AGENT,
    AUTOCOMPLETE_FIND_MERCHANTS,
    AUTOCOMPLETE_SEARCH_AGENTS
} from './autocomplete/actions'
import { AutocompleteSagas } from './autocomplete/sagas'
import { CONTRACTS_LOAD_CONTRACTS } from './contracts/actions'
import { ContractsSagas } from './contracts/sagas'
import { DASHBOARD_LOAD_STATS } from './dashboard/actions'
import { DashboardSagas } from './dashboard/sagas'
import {
    DISPUTE_CHANGE_DUEDATE,
    DISPUTE_LOAD,
    DISPUTE_LOAD_EXTRA_DETAILS,
    DISPUTE_REMOVE_COMMENT,
    DISPUTE_SEND_COMMENT,
    DISPUTE_STAMP,
    DISPUTES_LOAD_SUMMARIES,
    DISPUTE_LOAD_NEXT_RESULTS
} from './disputes/actions'
import { DisputesSaga } from './disputes/sagas'
import {
    CALLS_LOAD_SUMMARIES,
    CALLS_UPDATE_SUMMARIES,
    CALLS_LOAD_QUEUES,
    CALLS_LOAD_PHONE_NAME,
    CALL_FIND_RESOURCES,
    CALL_LOAD_RESOURCES,
    CALL_ADD_RESOURCE,
    CALL_DELETE_RESOURCE,
    CALLS_REMOVE_QUEUE_NUMBER
} from './calls/actions'
import { CallsSaga } from './calls/sagas'
import { CallsPrefActions } from './calls-preferences/actions'
import { CallsPrefSaga } from './calls-preferences/sagas'
import {
    FILE_DELETE, FILE_GENERATE_DOWNLOAD_LINK, FILE_REPLACE, FILES_UPLOAD, FILE_FETCH, FILE_LOOKOUT_FETCH, FILE_LOOKOUT_REFETCH
} from './files/actions'
import { FilesSagas } from './files/sagas'
import { GATEWAYS_CREATE, GATEWAYS_EDIT, GATEWAYS_LOAD } from './gateways/actions'
import { GatewaysSaga } from './gateways/sagas'
import { InterfaceActions } from './interface/actions'
import { GlobalSagas } from './global/sagas'
import { VouchersActions } from './manage/vouchers/actions'
import { MCCActions } from './mcc/actions'
import { MCCSagas } from './mcc/sagas'
import { MerchantActions } from './merchant/actions'
import { MerchantSagas } from './merchant/sagas'
import { MerchantAccountsActions } from './merchantAccounts/actions'
import { MerchantAccountsSagas } from './merchantAccounts/sagas'
import { MerchantTimelineActions } from './merchantTimeline/actions'
import { MerchantTimelineSagas } from './merchantTimeline/sagas'
import { SEARCH_FIND_RESULTS } from './search/actions'
import { SearchSagas } from './search/sagas'
import {
    SETTLEMENTS_LOAD_MERCHANT_DETAILS,
    SETTLEMENTS_LOAD_SUMMARIES,
    SETTLEMENTS_LOAD_TRANSACTIONS,
    SETTLEMENTS_RESERVE_CANCEL,
    SETTLEMENT_FETCH_SETTLEMENT
} from './settlements/actions'
import { SettlementsSagas } from './settlements/sagas'
import {
    TASK_ASSIGN_AGENT,
    TASK_ASSIGN_TAG,
    TASK_CLOSE,
    TASK_CREATE_AND_ASSIGN_TAG,
    TASK_EDIT_COMMENT,
    TASK_LOAD_TIMELINE,
    TASK_OPEN,
    TASK_REMOVE_COMMENT,
    TASK_REMOVE_TAG,
    TASK_SEND_COMMENT,
    TASKS_LOAD_SUMMARIES,
    TASKS_LOAD_TAGS,
    TASKS_LOAD_TASK,
    TASKS_UNSTORE_TASK
} from './tasks/actions'
import { TasksCancellableSagas } from './tasks/cancellableSagas'
import { TasksSaga } from './tasks/sagas'
import { TOASTS_PUSH } from './toasts/actions'
import { ToastSagas } from './toasts/sagas'
import {
    TRANSACTION_LOAD,
    TRANSACTION_LOAD_EXTRA_DETAILS,
    TRANSACTIONS_LOAD_NEXT_SUMMARIES,
    TRANSACTIONS_LOAD_SUMMARIES,
    TRANSACTION_LOAD_APPENDIX
} from './transactions/actions'
import { TransactionsSagas } from './transactions/sagas'
import {
    USER_ACCOUNTS_GET_ACCOUNT,
    USER_ACCOUNTS_GET_ACCOUNTS,
    USER_ACCOUNTS_LOAD_ACCOUNT,
    USER_ACCOUNTS_QUERY,
    UserAccountsDispatchGetAccounts
} from './userAccounts/actions'
import { RequestBatcher, UserAccountsSagas } from './userAccounts/sagas'
import { WATCHER_FAIL, WATCHER_SUCCESS } from './watcher/actions'
import { WatcherSagas } from './watcher/sagas'
import { ContractTemplateActions } from './contractTemplates/actions'
import { ContractTemplatesSagas } from './contractTemplates/sagas'
import { ApplicationInternalsNeedingAttentionActions } from './applicationInternals/needingAttention/actions'
import { BVDActions } from './applicationInternals/bvd/actions'
import { BVDSagas } from './applicationInternals/bvd/sagas'
import { ApplicationLoadingActions } from './applicationLoading/actions'
import { ApplicationLoadingSagas } from './applicationLoading/sagas'
import { ExportsActions } from './exports/actions'
import { ExportsSagas } from './exports/sagas'
import { SettlementsReservesActions } from './settlementsReserves/actions'
import { SettlementsReservesSagas } from './settlementsReserves/sagas'
import { ApplicationInternalsStampingActions } from './applicationInternals/stamping/actions'
import { ApplicationInternalsStampingSagas } from './applicationInternals/stamping/sagas'
import { GlobalActions } from './global/actions'
import { ApplicationDataProvidersActions } from './applicationDataProviders/actions'
import { ApplicationDataProvidersSagas } from './applicationDataProviders/sagas'
import { ApplicationInternalsRecordActions } from './applicationInternals/record/actions'
import { ApplicationInternalsRecordSagas } from './applicationInternals/record/sagas'
import { TasksCompanyChangesActions } from './tasksCompanyChanges/actions'
import { TasksCompanyChangesSagas } from './tasksCompanyChanges/sagas'

const authSagas = new AuthSagas()
const dashboardSagas = new DashboardSagas()
const applicationsSagas = new ApplicationsSagas()
const gatewaysSagas = new GatewaysSaga()
const accountsSagas = new UserAccountsSagas()
const filesSagas = new FilesSagas()
const tasksSagas = new TasksSaga()
const tasksCancellableSagas = new TasksCancellableSagas(tasksSagas)
const searchSagas = new SearchSagas()
const contractsSagas = new ContractsSagas()
const settlementsSagas = new SettlementsSagas()
const watcherSagas = new WatcherSagas()
const autocompleteSagas = new AutocompleteSagas()
const toastSagas = new ToastSagas()
const disputesSagas = new DisputesSaga()
const batcher = new RequestBatcher(UserAccountsDispatchGetAccounts)
const transactionsSagas = new TransactionsSagas()
const callsSagas = new CallsSaga()

function* rootSaga() {
    yield all([
        takeLatest(DASHBOARD_LOAD_STATS, dashboardSagas.loadStats),

        takeEvery(USER_ACCOUNTS_GET_ACCOUNT, RequestBatcher.batchRequests, batcher.state),
        takeEvery(USER_ACCOUNTS_GET_ACCOUNTS, accountsSagas.getAccounts),
        takeEvery(USER_ACCOUNTS_LOAD_ACCOUNT, accountsSagas.loadAccount),
        takeEvery(USER_ACCOUNTS_QUERY, accountsSagas.queryAccounts),

        takeLatest(APPLICATIONS_LOAD_ENTRIES, applicationsSagas.loadEntries),
        takeLatest(APPLICATIONS_LOAD_APPLICATION, applicationsSagas.loadApplication),
        takeLatest(APPLICATIONS_STORE_APPLICATION, applicationsSagas.storeApplication),

        takeEvery(ApplicationResourceActions.REMOVE_SUBSECTION, ApplicationResourceSagas.REMOVE_SUBSECTION),
        takeEvery(ApplicationResourceActions.ADD_SUBSECTION, ApplicationResourceSagas.ADD_SUBSECTION),
        takeEvery(ApplicationResourceActions.FETCH_COMPANY, ApplicationResourceSagas.FETCH_COMPANY),
        takeEvery(ApplicationResourceActions.SELECT_GATEWAY, ApplicationResourceSagas.SELECT_GATEWAY),
        takeEvery(ApplicationResourceActions.FETCH_CONTACT, ApplicationResourceSagas.FETCH_CONTACT),
        takeEvery(ApplicationResourceActions.FETCH_WEBSITES, ApplicationResourceSagas.FETCH_WEBSITES),
        takeEvery(ApplicationResourceActions.FETCH_MORE_WEBSITES, ApplicationResourceSagas.FETCH_MORE_WEBSITES),
        takeEvery(ApplicationResourceActions.FETCH_WEBSITES_HISTORY, ApplicationResourceSagas.FETCH_WEBSITES_HISTORY),
        takeEvery(ApplicationResourceActions.FETCH_COMMENTS, ApplicationResourceSagas.FETCH_COMMENTS),
        takeEvery(ApplicationResourceActions.FETCH_GATEWAY, ApplicationResourceSagas.FETCH_GATEWAY),
        takeEvery(ApplicationResourceActions.FETCH_PEOPLE, ApplicationResourceSagas.FETCH_PEOPLE),
        takeEvery(ApplicationResourceActions.FETCH_PEOPLE_HISTORY, ApplicationResourceSagas.FETCH_PEOPLE_HISTORY),
        takeEvery(ApplicationResourceActions.STORE_BUSINESS_MODEL, ApplicationResourceSagas.STORE_BUSINESS_MODEL),
        takeEvery(ApplicationResourceActions.STORE_ADDITIONAL, ApplicationResourceSagas.STORE_ADDITIONAL),
        takeEvery(ApplicationResourceActions.CLEANUP, ApplicationResourceSagas.CLEANUP),
        takeEvery(ApplicationResourceActions.STORE_SIGNER, ApplicationResourceSagas.STORE_SIGNER),
        takeEvery(ApplicationResourceActions.LINK_FILES, ApplicationResourceSagas.LINK_FILES),
        takeEvery(ApplicationResourceActions.UNLINK_FILES, ApplicationResourceSagas.UNLINK_FILES),

        takeEvery(ApplicationDataProvidersActions.FETCH, ApplicationDataProvidersSagas.FETCH),
        takeEvery(ApplicationDataProvidersActions.IGNORE_CHANGES, ApplicationDataProvidersSagas.IGNORE_CHANGES),
        takeEvery(ApplicationDataProvidersActions.UNIGNORE_CHANGES, ApplicationDataProvidersSagas.UNIGNORE_CHANGES),
        takeEvery(ApplicationDataProvidersActions.SEARCH_FOR_MATCHES, ApplicationDataProvidersSagas.SEARCH_FOR_MATCHES),
        takeEvery(ApplicationDataProvidersActions.SCAN_FOR_CONFLICTS, ApplicationDataProvidersSagas.SCAN_FOR_CONFLICTS),
        takeEvery(ApplicationDataProvidersActions.SELECT_RESULT, ApplicationDataProvidersSagas.SELECT_RESULT),
        takeEvery(ApplicationDataProvidersActions.UNLINK, ApplicationDataProvidersSagas.UNLINK),
        takeEvery(ApplicationDataProvidersActions.EDIT_ROLE, ApplicationDataProvidersSagas.EDIT_ROLE),
        takeEvery(ApplicationDataProvidersActions.FIX_TYPOS, ApplicationDataProvidersSagas.FIX_TYPOS),
        takeEvery(
            ApplicationDataProvidersActions.STORE_MUTED_CONFLICTS,
            ApplicationDataProvidersSagas.ON_MUTED_CONFLICTS_UPDATED
        ),

        takeEvery(ApplicationResourceActions.FETCH_BANK_ACCOUNT, ApplicationResourceSagas.FETCH_BANK_ACCOUNT),
        takeEvery(ApplicationResourceActions.LOAD_FILES, ApplicationResourceSagas.LOAD_FILES),
        takeEvery(ApplicationResourceActions.SEND_COMMENT, ApplicationResourceSagas.SEND_COMMENT),
        takeEvery(ApplicationResourceActions.REMOVE_COMMENT, ApplicationResourceSagas.REMOVE_COMMENT),
        takeEvery(ApplicationResourceActions.STORE, ApplicationResourceSagas.STORE),
        takeEvery(ApplicationResourceActions.MAKE_EDITS, ApplicationResourceSagas.MAKE_EDITS),
        takeEvery(ApplicationResourceActions.EDIT_ITEMS, ApplicationResourceSagas.EDIT_ITEMS),

        takeEvery(InterfaceActions.START_APPLICATION_REVIEW, InterfaceSagas.START_APPLICATION_REVIEW),

        takeEvery(BVDActions.FETCH_BVD, BVDSagas.FETCH_BVD),

        takeEvery(
            ApplicationInternalsChecksActions.TOGGLE_SECTION_CHECK,
            ApplicationInternalsSagas.TOGGLE_SECTION_CHECK
        ),

        takeEvery(ApplicationInternalsStampingActions.STAMP, ApplicationInternalsStampingSagas.STAMP),
        takeEvery(ApplicationInternalsStampingActions.REFETCH, ApplicationInternalsStampingSagas.REFETCH),

        takeEvery(ApplicationInternalsCollaboratorsActions.LOAD, ApplicationInternalsCollaboratorsSagas.LOAD),

        takeEvery(
            ApplicationInternalsCollaboratorsActions.RESEND_INVITATION,
            ApplicationInternalsCollaboratorsSagas.RESEND_INVITATION
        ),

        takeLatest(
            ApplicationInternalsNeedingAttentionActions.SCAN_COMMENTS,
            ApplicationInternalsNeedingAttentionSagas.SCAN_COMMENTS
        ),
        takeLatest(ApplicationInternalsRecordActions.FETCH, ApplicationInternalsRecordSagas.FETCH),
        takeEvery(
            ApplicationInternalsRecordActions.REVOKE_NOTIFICATION,
            ApplicationInternalsRecordSagas.REVOKE_NOTIFICATION
        ),
        takeEvery(
            ApplicationInternalsRecordActions.UPDATE_NOTIFICATION,
            ApplicationInternalsRecordSagas.UPDATE_NOTIFICATION
        ),
        takeEvery(
            ApplicationInternalsRecordActions.CREATE_NOTIFICATION,
            ApplicationInternalsRecordSagas.CREATE_NOTIFICATION
        ),
        takeEvery(ApplicationInternalsCollaboratorsActions.REVOKE, ApplicationInternalsCollaboratorsSagas.REVOKE),

        takeEvery(ApplicationInternalsCollaboratorsActions.INVITE, ApplicationInternalsCollaboratorsSagas.INVITE),


        takeEvery(SettlementsReservesActions.START_FLOW, SettlementsReservesSagas.START_FLOW),
        takeLatest(SettlementsReservesActions.AUTOSELECT, SettlementsReservesSagas.AUTOSELECT),
        takeEvery(SettlementsReservesActions.CANCEL_FLOW, SettlementsReservesSagas.CANCEL_FLOW),
        takeEvery(
            SettlementsReservesActions.FETCH_SETTLEMENTS_WITH_RESERVES,
            SettlementsReservesSagas.FETCH_SETTLEMENTS_WITH_RESERVES
        ),
        takeLatest(
            SettlementsReservesActions.START_CANCELLING_RESERVES,
            SettlementsReservesSagas.START_CANCELLING_RESERVES
        ),
        takeEvery(SettlementsReservesActions.SET_RESERVE_STATUS, SettlementsReservesSagas.ON_RESERVE_STATUS_CHANGE),
        takeEvery(SettlementsReservesActions.INFUSE_STATE, SettlementsReservesSagas.ON_STATE_INFUSION),
        takeEvery(
            SettlementsReservesActions.PREPARE_FOR_GRACEFUL_STOP,
            SettlementsReservesSagas.PREPARE_FOR_GRACEFUL_STOP
        ),
        takeLatest(SettlementsReservesActions.RETRY_FROM_FAILED, SettlementsReservesSagas.RETRY_FROM_FAILED),

        takeLatest(SettlementsReservesActions.RETRY_FROM_FAILED, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.ABORT_PROCESS, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.INTERRUPT_PROCESS, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.SAVE_AUTOSELECT_SOLUTION, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.FINISH_RESERVES_CANCELLATION, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.SELECT_SETTLEMENTS, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.DESELECT_SETTLEMENTS, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.EXCLUSIVELY_SELECT_SETTLEMENTS, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.START_CANCELLING_RESERVES, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.SET_RESERVE_STATUS, SettlementsReservesSagas.PERSIST),
        takeLatest(SettlementsReservesActions.SET_RESERVES_SORT, SettlementsReservesSagas.PERSIST),
        takeEvery(SettlementsReservesActions.START_FLOW, SettlementsReservesSagas.PERSIST),
        takeLeading(SettlementsReservesActions.AUTOSELECT, SettlementsReservesSagas.PERSIST),

        // TAGS
        takeLatest(ApplicationInternalsTagsActions.FETCH, ApplicationInternalsTagsSagas.FETCH),
        takeEvery(ApplicationInternalsTagsActions.ASSIGN, ApplicationInternalsTagsSagas.ASSIGN),
        takeEvery(ApplicationInternalsTagsActions.UNASSIGN, ApplicationInternalsTagsSagas.UNASSIGN),
        takeEvery(ApplicationInternalsTagsActions.MODIFY, ApplicationInternalsTagsSagas.MODIFY),
        takeEvery(ApplicationInternalsTagsActions.CREATE, ApplicationInternalsTagsSagas.CREATE),
        takeEvery(
            ApplicationInternalsTagsActions.FETCH_APPLICATION_TAGS,
            ApplicationInternalsTagsSagas.FETCH_APPLICATION_TAGS
        ),

        takeEvery(ApplicationInternalsDetailsActions.UPDATE_VALUE, ApplicationInternalsDetailsSagas.UPDATE_VALUE),
        takeEvery(ApplicationInternalsDetailsActions.CLONE, ApplicationInternalsDetailsSagas.CLONE),

        takeEvery(ApplicationInternalsAgentsActions.ASSIGN_AGENT, ApplicationInternalsAgentsSagas.ASSIGN_AGENT),

        takeLatest(ApplicationInternalsReferralActions.FETCH, ApplicationInternalsReferralSagas.FETCH),
        takeEvery(ApplicationInternalsReferralActions.REMOVE, ApplicationInternalsReferralSagas.REMOVE),
        takeEvery(ApplicationInternalsAgentsActions.FETCH, ApplicationInternalsAgentsSagas.FETCH),
        takeEvery(ApplicationInternalsReferralActions.ADD, ApplicationInternalsReferralSagas.ADD),

        // Old HARVESTER (commented out due to service being shut down)
        /* takeLatest(ApplicationInternalsHarvesterActions.FETCH, ApplicationInternalsHarvesterSagas.FETCH),
        takeLatest(
            ApplicationInternalsHarvesterActions.COMPUTE_ISSUES,
            ApplicationInternalsHarvesterSagas.COMPUTE_ISSUES
        ),

        takeLatest(
            ApplicationInternalsHarvesterActions.COMPUTE_ADDITIONAL_INFO,
            ApplicationInternalsHarvesterSagas.COMPUTE_ADDITIONAL_INFO
        ),

        takeLatest(
            ApplicationInternalsHarvesterActions.COMPUTE_CHECKS,
            ApplicationInternalsHarvesterSagas.COMPUTE_CHECKS
        ), */

        takeLatest(APPLICATIONS_EDIT_ENTITY, applicationsSagas.editEntity),
        takeLatest(APPLICATIONS_LOAD_CVR_INFO, applicationsSagas.loadCVR),
        takeLatest(APPLICATIONS_LOAD_BVD_INFO, applicationsSagas.loadBVD),
        takeLatest(APPLICATIONS_UPDATE_DRAFT_AGREEMENT, applicationsSagas.updateDraftAgreement),
        takeLatest(APPLICATIONS_CREATE_DRAFT_AGREEMENT, applicationsSagas.createDraftAgreement),
        takeLatest(APPLICATIONS_APPROVE_DRAFT_AGREEMENT, applicationsSagas.approveDraftAgreement),
        takeLatest(APPLICATIONS_REFUSE_DRAFT_AGREEMENT, applicationsSagas.refuseDraftAgreement),
        takeLatest(APPLICATIONS_UPDATE_CUTTER_COMMENTS_INFO, applicationsSagas.updateCutterCommentsInfo),
        takeLatest(APPLICATIONS_FETCH_CUTTER_INFO, applicationsSagas.fetchCutterInfo),
        takeEvery(APPLICATIONS_SEND_INTERNAL_COMMENT, applicationsSagas.sendInternalComment),
        takeEvery(APPLICATIONS_STORE_INTERNAL_COMMENT, applicationsSagas.onStoreInternalComment),
        takeLatest(APPLICATIONS_REMOVE_INTERNAL_COMMENT, applicationsSagas.removeInternalComment),
        takeEvery(APPLICATIONS_MARK_COMMENT_AS_READ, applicationsSagas.markCommentAsRead),
        takeLatest(APPLICATIONS_STORE_READ_COMMENT, applicationsSagas.onStoreReadComment),
        takeLatest(APPLICATIONS_STORE_CUTTER_COMMENTS_INFO, applicationsSagas.onStoreCutterCommentsInfo),

        takeLatest(VouchersActions.LOAD, VouchersSagas.LOAD),
        takeEvery(VouchersActions.NEW, VouchersSagas.NEW),

        takeLatest(GATEWAYS_LOAD, gatewaysSagas.loadGateways),
        takeEvery(GATEWAYS_CREATE, gatewaysSagas.createGateway),
        takeEvery(GATEWAYS_EDIT, gatewaysSagas.editGateway),

        takeLatest(ContractTemplateActions.FETCH_ALL, ContractTemplatesSagas.FETCH_ALL),
        takeLatest(ContractTemplateActions.FETCH_SINGLE, ContractTemplatesSagas.FETCH_SINGLE),
        takeEvery(ContractTemplateActions.UPDATE, ContractTemplatesSagas.UPDATE),

        takeEvery(ContractTemplateActions.BOOTSTRAP, ContractTemplatesSagas.BOOTSTRAP),
        takeEvery(ContractTemplateActions.DELETE, ContractTemplatesSagas.DELETE),

        takeEvery(TasksCompanyChangesActions.INIT, TasksCompanyChangesSagas.INIT),
        takeEvery(TasksCompanyChangesActions.CHANGE_MUTED_STATE, TasksCompanyChangesSagas.CHANGE_MUTED_STATE),
        takeEvery(TasksCompanyChangesActions.REFRESH_APPLICATION, TasksCompanyChangesSagas.REFRESH_APPLICATION),
        takeEvery(TasksCompanyChangesActions.APPLY_CHANGE, TasksCompanyChangesSagas.APPLY_CHANGE),

        takeLatest(CONTRACTS_LOAD_CONTRACTS, contractsSagas.loadContracts),

        takeLatest(TASKS_LOAD_SUMMARIES, tasksSagas.loadSummaries),
        takeLatest(TASKS_LOAD_TAGS, tasksSagas.loadTags),
        takeLatest(TASKS_UNSTORE_TASK, tasksCancellableSagas.cancelTasksLoadingTasks),
        takeLatest(TASKS_LOAD_TASK, tasksCancellableSagas.loadTask),
        takeLatest(TASK_LOAD_TIMELINE, tasksCancellableSagas.loadTimeline),
        takeEvery(TASK_REMOVE_TAG, tasksSagas.removeTag),
        takeEvery(TASK_ASSIGN_TAG, tasksSagas.assignTag),
        takeEvery(TASK_CREATE_AND_ASSIGN_TAG, tasksSagas.createTag),
        takeEvery(TASK_ASSIGN_AGENT, tasksSagas.assignAgent),
        takeEvery(TASK_OPEN, tasksSagas.open),
        takeEvery(TASK_CLOSE, tasksSagas.close),
        takeEvery(TASK_SEND_COMMENT, tasksSagas.sendComment),
        takeEvery(TASK_EDIT_COMMENT, tasksSagas.editComment),
        takeEvery(TASK_REMOVE_COMMENT, tasksSagas.removeComment),
        takeLatest(DISPUTES_LOAD_SUMMARIES, disputesSagas.loadSummaries),
        takeLatest(DISPUTE_LOAD_EXTRA_DETAILS, disputesSagas.loadExtraDetails),
        takeLatest(DISPUTE_LOAD, disputesSagas.load),
        takeEvery(DISPUTE_STAMP, disputesSagas.stamp),
        takeEvery(DISPUTE_CHANGE_DUEDATE, disputesSagas.changeDueDate),
        takeEvery(DISPUTE_REMOVE_COMMENT, disputesSagas.removeComment),
        takeEvery(DISPUTE_SEND_COMMENT, disputesSagas.sendComment),
        takeLatest(DISPUTE_LOAD_NEXT_RESULTS, disputesSagas.loadNextResults),
        takeLatest(CALLS_LOAD_SUMMARIES, callsSagas.loadSummaries),
        takeLatest(CALLS_UPDATE_SUMMARIES, callsSagas.updateSummaries),
        takeEvery(CALL_ADD_RESOURCE, callsSagas.addResource),
        takeEvery(CALL_DELETE_RESOURCE, callsSagas.deleteResource),
        takeLatest(CALLS_LOAD_QUEUES, callsSagas.loadQueues),
        takeLatest(CALL_LOAD_RESOURCES, callsSagas.loadResources),
        takeLatest(CALLS_LOAD_PHONE_NAME, callsSagas.getPhoneName),
        takeEvery(CALLS_REMOVE_QUEUE_NUMBER, callsSagas.removeNumber),
        takeLatest(SETTLEMENTS_LOAD_SUMMARIES, settlementsSagas.loadSummaries),
        takeLatest(SETTLEMENTS_LOAD_MERCHANT_DETAILS, settlementsSagas.loadMerchantDetails),
        takeLatest(SETTLEMENTS_LOAD_TRANSACTIONS, settlementsSagas.loadTransactions),
        takeLatest(SETTLEMENT_FETCH_SETTLEMENT, settlementsSagas.fetchSettlement),
        takeEvery(SETTLEMENTS_RESERVE_CANCEL, settlementsSagas.reserveCancel),
        takeLatest(MerchantAccountsActions.FETCH, MerchantAccountsSagas.FETCH),
        takeLatest(MerchantAccountsActions.FETCH_ACCOUNT, MerchantAccountsSagas.FETCH_ACCOUNT),
        takeLatest(MerchantAccountsActions.FETCH_CONTRACT_HISTORY, MerchantAccountsSagas.FETCH_CONTRACT_HISTORY),
        takeLatest(MerchantActions.LOAD, MerchantSagas.LOAD),
        takeEvery(MerchantActions.CLEANUP, MerchantSagas.CLEANUP),
        takeLatest(MerchantAccountsActions.UPDATE_DRAFT, MerchantAccountsSagas.UPDATE_DRAFT),
        takeEvery(
            MerchantAccountsActions.REFETCH_UPDATED_DRAFT_CONTRACT,
            MerchantAccountsSagas.REFETCH_UPDATED_DRAFT_CONTRACT
        ),
        takeEvery(MerchantAccountsActions.STAMP_CONTRACT, MerchantAccountsSagas.STAMP_CONTRACT),
        takeEvery(MerchantAccountsActions.STAMP_DRAFT, MerchantAccountsSagas.STAMP_DRAFT),
        takeEvery(MerchantAccountsActions.CREATE_ACCOUNT, MerchantAccountsSagas.CREATE_ACCOUNT),
        takeEvery(MerchantAccountsActions.UPDATE_ACCOUNT, MerchantAccountsSagas.UPDATE_ACCOUNT),
        takeEvery(MerchantAccountsActions.STAMP_ACCOUNT, MerchantAccountsSagas.STAMP_ACCOUNT),
        takeEvery(MerchantAccountsActions.CREATE_API_KEY, MerchantAccountsSagas.CREATE_API_KEY),
        takeEvery(MerchantAccountsActions.UPDATE_API_KEY, MerchantAccountsSagas.UPDATE_API_KEY),
        takeEvery(MerchantAccountsActions.INVITE_MEMBER, MerchantAccountsSagas.INVITE_MEMBER),
        takeEvery(MerchantAccountsActions.REMOVE_MEMBER, MerchantAccountsSagas.REMOVE_MEMBER),
        takeEvery(MerchantTimelineActions.PROCESS, MerchantTimelineSagas.PROCESS),

        takeEvery(ExportsActions.FETCH_EXPORTS, ExportsSagas.FETCH_EXPORTS),
        takeEvery(ExportsActions.TRIGGER_EXPORT, ExportsSagas.TRIGGER_EXPORT),
        takeEvery(ExportsActions.DOWNLOAD, ExportsSagas.DOWNLOAD),

        takeLatest(TRANSACTIONS_LOAD_SUMMARIES, transactionsSagas.loadSummaries),
        takeLatest(TRANSACTIONS_LOAD_NEXT_SUMMARIES, transactionsSagas.loadNextSummaries),
        takeLatest(TRANSACTION_LOAD, transactionsSagas.load),
        takeLatest(TRANSACTION_LOAD_EXTRA_DETAILS, transactionsSagas.loadExtraDetails),
        takeLatest(TRANSACTION_LOAD_APPENDIX, transactionsSagas.loadAppendix),

        takeLatest(ApplicationInternalsWarningsActions.GENERATE, ApplicationInternalsWarningsSagas.GENERATE),

        takeEvery(TOASTS_PUSH, toastSagas.pushToast),

        takeLatest(SEARCH_FIND_RESULTS, searchSagas.findResults),
        takeLeading(CALL_FIND_RESOURCES, callsSagas.findResources),

        takeEvery(AUTOCOMPLETE_SEARCH_AGENTS, autocompleteSagas.searchAgents),
        takeEvery(MCCActions.MCC_FIND, MCCSagas.FETCH),
        takeEvery(AUTOCOMPLETE_FETCH_SELECTED_AGENT, autocompleteSagas.fetchSelectedAgent),
        takeEvery(AUTOCOMPLETE_FIND_MERCHANTS, autocompleteSagas.findMerchants),
        takeEvery(FILES_UPLOAD, filesSagas.uploadFiles),
        takeEvery(FILE_FETCH, filesSagas.fetchFile),
        takeEvery(FILE_LOOKOUT_FETCH, filesSagas.fetchLookoutFile),
        takeEvery(FILE_LOOKOUT_REFETCH, filesSagas.refetchLookoutFile),
        takeEvery(FILE_DELETE, filesSagas.deleteFile),
        takeEvery(FILE_REPLACE, filesSagas.replaceFile),
        takeEvery(FILE_GENERATE_DOWNLOAD_LINK, filesSagas.generateDownloadLink),

        takeEvery(APPLICATIONS_CLEANUP, applicationsSagas.cleanup),

        takeLatest(InterfaceActions.TOGGLE_THEME, InterfaceSagas.TOGGLE_THEME),
        takeLatest(InterfaceActions.INIT_CRITICAL_FLOW_IF_IT_EXISTS, InterfaceSagas.INIT_CRITICAL_FLOW_IF_IT_EXISTS),
        takeLatest(InterfaceActions.COMPLETE_CRITICAL_FLOW, InterfaceSagas.ON_COMPLETE_CRITICAL_FLOW),
        takeLatest(InterfaceActions.START_CRITICAL_FLOW, SettlementsReservesSagas.ON_FLOW_INIT),

        takeLatest(CallsPrefActions.UPDATE_PREF, CallsPrefSaga.UPDATE_PREF),
        takeLatest(CallsPrefActions.SET_IS_CALLING, CallsPrefSaga.SET_IS_CALLING),

        takeLatest(AUTH_INIT, authSagas.init),
        takeLatest(AUTH_LOGIN, authSagas.login),
        takeLatest(AUTH_STORE_USER, authSagas.onUserStore),
        takeLatest(AUTH_LOGOUT, authSagas.logout),

        takeLatest(ApplicationInternalsRelatedActions.LOAD, ApplicationInternalsRelatedSagas.LOAD),
        takeLatest(ApplicationInternalsRelatedActions.LOAD_NEXT, ApplicationInternalsRelatedSagas.LOAD_NEXT),
        takeLatest(GlobalActions.FETCH_LINKS, GlobalSagas.FETCH_LINKS),

        takeEvery(WATCHER_SUCCESS, watcherSagas.success),
        takeEvery(WATCHER_FAIL, watcherSagas.throw),

        takeEvery(
            ApplicationLoadingActions.APPLICATION_STARTED_LOADING,
            ApplicationLoadingSagas.APPLICATION_STARTED_LOADING
        ),
        takeLeading(ApplicationLoadingActions.LOAD_TASKS_COUNT, ApplicationLoadingSagas.LOAD_TASKS_COUNT)
    ])
}

export default rootSaga
