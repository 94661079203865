import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '@/store'
import { UserAccountsDispatchGetAccount } from '../../store/userAccounts/actions'
import { UserAccount as AccountDatatype } from '../../store/userAccounts/types'
import { TextLineLoader } from './../loaders/textLineLoader'
import { Text } from '../../components/general/text'

const showNone = () => {
    return <Text>-</Text>
}

const showUnknown = () => {
    return <Text>Unknown</Text>
}

export const Account: React.FC<{
    id: string
    merchant?: boolean
    showOnlyMID?: boolean
}> = ({ id, merchant = false, showOnlyMID = false }) => {
    const account: AccountDatatype = useSelector((state: RootState) => state.userAccounts.at[id])
    const dispatch = useDispatch()

    useEffect(() => {
        if (id && (!account || account.loadingStatus === 'not-started'))
            dispatch(UserAccountsDispatchGetAccount(id, { isMerchant: merchant }))
    }, [id, account, dispatch, merchant])

    if (!id) return showNone()

    if (!account || account.loadingStatus === 'not-started' || account.loadingStatus === 'started')
        return (
            <LoaderOffset>
                <TextLineLoader maxWidth={merchant ? 40 : 120} minWidth={merchant ? 40 : 60} height={13} />
            </LoaderOffset>
        )

    if (!account.data) return <Text noWrap>Unknown user</Text>

    let text = account.data.name

    if (account.data.email) {
        text = account.data.email
    }

    if (merchant && account.data.merchantId && showOnlyMID) {
        text = account.data.merchantId
    }

    if (!text) return showUnknown()

    return <Text noWrap>{text}</Text>
}

const LoaderOffset = styled.span`
    position: relative;
    top: 2px;
`
