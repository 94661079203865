import React, { useMemo, useState } from 'react'
import { PageHeader } from '../../components/layout/pageHeader'
import { PostingsTable } from '@/features/reconciliation'
import { useAuth } from '@/hooks/auth/useAuth'
import { Flex } from '@/components/layout/flex'
import { Text } from '../../components/general/text'
import { TableFuzzySearch } from '@/components/layout/tableFuzzySearch'
// eslint-disable-next-line
import {
    appendActions,
    getPostingCols,
    getPostingRows
} from '@/features/reconciliation/components/postings/postings-table/helpers'
import { useGetAccounts, useGetPostings } from '@/services/reconciliation'
import { TableRow } from '@/components/tables/table'

export const UnreconciledPostings: React.FC = () => {
    const { user } = useAuth()
    const isFinanceAgent = user?.['https://clearhaus.com/app_metadata'].roles.includes('finance')

    const { isLoading: postingsLoading, data: postings } = useGetPostings()
    const { isLoading: accountsLoading, data: accounts } = useGetAccounts()
    const [filteredRows, setFilteredRows] = useState<TableRow[]>([])

    const { cols, rows } = useMemo(() => {
       return appendActions(getPostingCols(), getPostingRows(postings?.items, accounts?.items))
    }, [postings, accounts])

    const leftSideMemo = useMemo(() => {
        return (
            <TableFuzzySearch
                headers={cols}
                queryParamName="reconciliation-query"
                rows={rows}
                setFilteredRows={setFilteredRows}
                ignoreHeaderWithText="Actions"
            />
        )
    }, [rows, setFilteredRows, cols])
    
    return (
        <>
            <PageHeader
                noBottomBorder
                title="Unreconciled postings"
                subtitle={filteredRows.length}
                leftSideMemo={leftSideMemo}
            />
            {isFinanceAgent ?
                <PostingsTable
                    rows={filteredRows}
                    cols={cols}
                    isLoading={postingsLoading || accountsLoading}
                 />
            :
                <Flex align="center" justify="center" grow column>
                    <Text bold color="back.text.strongerII">
                        Only finance employees can use this tool
                    </Text>
                </Flex>
            }
        </>
    )
}
