import { Box, Flex, Container, Loader } from '@/design-system'
import { DetailsSection, HeaderSection, getPartnerData } from '@/features/partners'
import { MAPI_PARTNERS_ROOT, useGetPartner, useGetSigningKey } from '@/services/merchant-api'
import React from 'react'
import { useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom'
import type {
    Integration as IntegrationType,
    Partner as PartnerType,
    SigningKey as SigningKeyType
} from '@/services/merchant-api'
import { IntegrationCard, IntegrationsSection, useEmbeddedIntegrations } from '@/features/integrations'
import { EmptyState } from '@/ui'
import {
    SigningKeysSection,
    SigningKeysTable,
    SigningKeysTableRow,
    useEmbeddedSigningKeys
} from '@/features/signing-keys'

const loader = async ({ request, params }) => {
    const { id } = params
    return await getPartnerData(id, request)
}

const Partner = () => {
    const { id } = useParams()
    const navigation = useNavigation()
    const partnerUrl = id ? `${MAPI_PARTNERS_ROOT}/${id}` : ''

    const partner = useGetPartner(partnerUrl).data

    const integrationsUrl = partner?.relations.integrations ? partner.relations.integrations : ''

    const { embeddedIntegrations } = useEmbeddedIntegrations(integrationsUrl)

    const isLoading = navigation.state === 'loading'

    return (
        <Loader isLoading={isLoading}>
            <Header partner={partner} id={id} />
            <Flex justify="center" grow="1">
                <Container size="screen-xl">
                    <Box my="5">
                        <Flex direction="column" gap="10">
                            <Details id={id} partner={partner} />
                            <Integrations id={id} integrations={embeddedIntegrations} />
                        </Flex>
                    </Box>
                </Container>
            </Flex>
        </Loader>
    )
}

interface HeaderProps {
    id?: string
    partner?: PartnerType
}

const Header: React.FC<HeaderProps> = (props) => {
    const { id, partner } = props

    const navigate = useNavigate()

    const handleOnBackClick = () => {
        navigate('/more/partners')
    }

    const handleOnActionClick = () => {
        navigate(`/more/partners/${id}/integrations/add`)
    }

    const hasIntegrations = !!partner?.relations.integrations

    return (
        <HeaderSection
            showAction={hasIntegrations}
            onBackClick={handleOnBackClick}
            onActionClick={handleOnActionClick}
        />
    )
}

interface DetailsProps {
    id?: string
    partner?: PartnerType
}

const Details: React.FC<DetailsProps> = (props) => {
    const { id, partner } = props

    const navigate = useNavigate()
    const location = useLocation()

    const handleOnEditClick = () => {
        navigate(`/more/partners/${id}/edit`, { state: { referrer: location.pathname } })
    }

    const name = partner?.name
    const technical = partner?.technical
    const support = partner?.support
    const referral = partner?.referral

    return (
        <DetailsSection
            name={name}
            technical={technical}
            support={support}
            referral={referral}
            onEditClick={handleOnEditClick}
        />
    )
}

interface IntegrationsProps {
    id?: string
    integrations: IntegrationType[]
}
const Integrations: React.FC<IntegrationsProps> = (props) => {
    const { id, integrations } = props

    const hasIntegrations = integrations && integrations.length > 0

    return (
        <IntegrationsSection>
            {hasIntegrations ? (
                integrations.map((integration) => (
                    <Integration key={integration.id} id={id} integration={integration} />
                ))
            ) : (
                <EmptyState>No integrations available</EmptyState>
            )}
        </IntegrationsSection>
    )
}

interface IntegrationProps {
    id?: string
    integration: IntegrationType
}

const Integration: React.FC<IntegrationProps> = (props) => {
    const { id, integration } = props
    const navigate = useNavigate()

    const handleOnEditClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        navigate(`/more/partners/${id}/integrations/${integration.id}/edit`)
    }

    return (
        <IntegrationCard
            name={integration.name}
            description={integration.description}
            transactionRules={integration.transaction_rules}
            partners={integration.embedded['ch:partners']}
            onEditClick={handleOnEditClick}
        >
            <SigningKeys id={id} integration={integration} />
        </IntegrationCard>
    )
}

interface SigningKeysProps {
    id?: string
    integration: IntegrationType
}

const SigningKeys: React.FC<SigningKeysProps> = (props) => {
    const { id, integration } = props

    const navigate = useNavigate()

    const url = integration.relations['signing-keys']
    const { embeddedSigningKeys } = useEmbeddedSigningKeys(url)
    const hasEmbeddedSigningKeys = embeddedSigningKeys && embeddedSigningKeys.length > 0

    const handleOnAddClick = () => {
        navigate(`/more/partners/${id}/integrations/${integration.id}/signing-keys/add`)
    }

    return (
        <SigningKeysSection onAddClick={handleOnAddClick}>
            {hasEmbeddedSigningKeys ? (
                <SigningKeysTable>
                    {embeddedSigningKeys.map((signingKey) => (
                        <SigningKey key={signingKey.id} id={id} signingKey={signingKey} integration={integration} />
                    ))}
                </SigningKeysTable>
            ) : (
                <EmptyState>No signing keys available</EmptyState>
            )}
        </SigningKeysSection>
    )
}

interface SigningKeyProps {
    id?: string
    signingKey: SigningKeyType
    integration: IntegrationType
}

const SigningKey: React.FC<SigningKeyProps> = (props) => {
    const { id, signingKey, integration } = props
    const navigate = useNavigate()

    const url = signingKey.relations.self
    const link = url ? url : ''

    const { data } = useGetSigningKey(link)

    const onEditClick = () => {
        navigate(`/more/partners/${id}/integrations/${integration.id}/signing-keys/${signingKey.id}/edit`)
    }

    if (!data) {
        return null
    }

    return <SigningKeysTableRow signingKey={data} onEditClick={onEditClick} />
}

Partner.loader = loader

export { Partner }
