import { api } from '../api'
import { ReconcileVoucherData } from '../types/reconciliation'
import { Voucher, Vouchers } from '../types/vouchers'

export const vouchersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getVouchers: build.query<Vouchers, void>({
            query: () => `/vouchers`,
            providesTags: ['vouchers']
        }),
        getVoucher: build.query<Voucher, string>({
            query: (voucherId: string) => `/vouchers/${voucherId}`,
            providesTags: ['voucher']
        }),
        reconcileVoucher: build.mutation<Voucher, { voucherId: string, data: ReconcileVoucherData }>({
            query: ({ voucherId, data }) => ({
                url: `/vouchers/${voucherId}/reconcile`,
                method: 'PUT',
                body: {
                    ...data
                }
            }),
            invalidatesTags: ['voucher', 'vouchers', 'postings']
        }),
        unreconcileVoucher: build.mutation<Voucher, { voucherId: string }>({
            query: ({ voucherId }) => ({ url: `/vouchers/${voucherId}/unreconcile`, method: 'PUT'}),
            invalidatesTags: ['voucher', 'vouchers', 'postings']
        })
    })
})

export const {
    useGetVouchersQuery: useGetVouchers,
    useGetVoucherQuery: useGetVoucher,
    useReconcileVoucherMutation: useReconcileVoucher,
    useUnreconcileVoucherMutation: useUnreconcileVoucher
} = vouchersApi
