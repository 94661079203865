import React from 'react'
import styled from 'styled-components'
import { ListPageSidebar } from '../../components/layout/listPageSidebar'
import { ListPageSidebarLinkStyles } from '../../components/layout/listPageSidebarLink'
import { ListPageSidebarTitle } from '../../components/layout/listPageSidebarTitle'
import { SmartNavLink } from '../../components/tables/smartNavLink'
import { useLocation } from 'react-router-dom'

export const ManageSidebar: React.FC = () => {
    const location = useLocation()
    return (
        <ListPageSidebar>
            <ListPageSidebarTitle>Referrals &amp; Gateways</ListPageSidebarTitle>
            <PageLink to={{ pathname: '/more/gateways', search: location.search }}>Gateways</PageLink>
            <PageLink to={{ pathname: '/more/vouchers', search: location.search }}>Referral vouchers</PageLink>
            <ListPageSidebarTitle>Partners</ListPageSidebarTitle>
            <PageLink to={{ pathname: '/more/partners', search: location.search }}>Partners</PageLink>
            <ListPageSidebarTitle>Merchants</ListPageSidebarTitle>
            <PageLink to={{ pathname: '/more/contract-templates', search: location.search }}>
                Contract templates
            </PageLink>
            <ListPageSidebarTitle>Finance</ListPageSidebarTitle>
            <PageLink to={{ pathname: '/more/unreconciled-vouchers', search: location.search }}>
                Unreconciled vouchers
            </PageLink>
            <PageLink to={{ pathname: '/more/unreconciled-postings', search: location.search }}>
                Unreconciled postings
            </PageLink>
            <ListPageSidebarTitle>Others</ListPageSidebarTitle>
            <PageLink to={{ pathname: '/more/exports', search: location.search }}>Exports</PageLink>
        </ListPageSidebar>
    )
}

const PageLink = styled(SmartNavLink)<{ isActive?: boolean }>`
    ${ListPageSidebarLinkStyles};
`
