import { useReconcilePosting, useReconcileVoucher } from '@/services/reconciliation'
import { useFormContext } from 'react-hook-form'
import { useReconciliationParams } from '../../hooks'

export const useReconcilePostingActions = () => {
    const { id } = useReconciliationParams()

    const [reconcilePosting, reconciliationResult] = useReconcilePosting()
    
    const isLoading = reconciliationResult.isLoading
    const methods = useFormContext()

    const reconcile = async () => {
        // create reconcilication
        const createPostingPayload = {
            postingId: id,
            data: {
                voucher_ids: methods.getValues("voucher_ids"),
                note: methods.getValues("note")
            }
        }
        
        const reconciliation = await reconcilePosting(createPostingPayload).unwrap()

        return { reconciliation }
    }

    return { isLoading, reconcile }
}

export const useReconcileVoucherActions = () => {
    const { id } = useReconciliationParams()

    const [reconcileVoucher, reconciliationResult] = useReconcileVoucher()

    const isLoading = reconciliationResult.isLoading
    const methods = useFormContext()

    const reconcile = async () => {
        // create reconcilication
        const createVoucherPayload = {
            voucherId: id,
            data: {
                posting_ids: methods.getValues("posting_ids"),
                note: methods.getValues("note")
            }
        }

        const reconciliation = await reconcileVoucher(createVoucherPayload).unwrap()

        return { reconciliation }
    }

    return { isLoading, reconcile }
}
