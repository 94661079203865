import { HoverCard, Link, Text } from '@/design-system'
import _ from 'lodash'
import React from 'react'

interface HoverCardTokenProps {
    token: string
}

export const HoverCardToken: React.FC<HoverCardTokenProps> = (props) => {
    const { token } = props

    const truncatedToken = _.truncate(token, { length: 11, omission: '...' })

    return (
        <HoverCard>
            <HoverCard.Trigger>
                <Link weight="medium" asChild>
                    <span>{truncatedToken}</span>
                </Link>
            </HoverCard.Trigger>
            <HoverCard.Content>
                <Text>{token}</Text>
            </HoverCard.Content>
        </HoverCard>
    )
}
