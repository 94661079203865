import { InternalNotesParams, MerchantInternalNotes } from './MerchantSidebarInternalNotes'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { BVDUISize } from '../../store/applicationInternals/bvd/types'
import { MerchantActions } from '../../store/merchant/actions'
import { MerchantApplicationCloneModal } from './Application/Application.Clone'
import { MerchantApplicationReviewChangesModal } from './Application/Application.ReviewChanges'
import { MerchantInteractions } from './MerchantSidebarInteractions'
import { RootState } from '@/store'
import { useDocumentScrollOverflowDisabler } from '../../hooks/pages/useDocumentScrollOverflowDisabler'
import { useQueryParams } from '../../hooks/general/useQueryParam'
import { Outlet, useParams } from 'react-router-dom'

type Params = { id: string }

export const MerchantRouter: React.FC = () => {
    const params = useParams() as Params

    const dispatch = useDispatch()
    const shouldReviewChanges = useSelector(
        (state: RootState) => state.interface.applications?.[params.id]?.shouldReviewChanges
    )
    const shouldClone = useSelector((state: RootState) => state.applicationInternals?.details?.[params.id]?.shouldClone)

    const haveInternalNotesLoaded = useSelector(
        (state: RootState) => state.applications.applications?.at?.[params.id]?.loadingStatus === 'done'
    )
    const [query] = useQueryParams(InternalNotesParams)

    useEffect(() => {
        dispatch(MerchantActions.LOAD(params.id))
        return () => {
            dispatch(MerchantActions.CLEANUP(params.id))
        }
    }, [dispatch, params.id])

    useDocumentScrollOverflowDisabler()

    if (shouldClone) return <MerchantApplicationCloneModal key="review" id={params.id} />

    if (shouldReviewChanges) return <MerchantApplicationReviewChangesModal key="review" id={params.id} />

    return (
        <>
            <Holder id="SCROLL-CONTAINER">
                <Outlet />
            </Holder>
            {haveInternalNotesLoaded && <MerchantInternalNotes shouldShow={query['internal-notes']} />}
            <MerchantInteractions />
        </>
    )
}

const Holder = styled.div<{ isSplit?: boolean; splitSize?: BVDUISize }>`
    position: relative;
    overflow-y: scroll;
    overscroll-behavior-y: none;
    height: 100vh;
    background-color: ${(p) => p.theme['back.background']};

    ${(p) => {
        if (!p.isSplit) return
        if (p.splitSize === 'mini')
            return css`
                height: calc(100vh - 3px);
            `
        if (p.splitSize === 'half') return css``
        if (p.splitSize === 'full')
            return css`
                height: 0;
                display: none;
            `
    }}
`
