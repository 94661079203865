import React from 'react'
import { GridStyles, ItemStyles, styles } from './styles'
import { cn } from '../utils'
import { Slot } from '@radix-ui/react-slot'

interface GridProps extends GridStyles {
    children: React.ReactNode
}

const Grid = (props: GridProps) => {
    const { children, display, columns, rows, gap, align, justify } = props

    const classnames = cn(styles.grid({ display, columns, rows, gap, align, justify }))

    return <div className={classnames}>{children}</div>
}

interface ItemProps extends ItemStyles {
    children: React.ReactNode
    asChild?: boolean
}

const Item: React.FC<ItemProps> = (props) => {
    const { children, asChild, columnSpan, columnStart, columnEnd, rowSpan, rowStart, rowEnd, align } = props

    const classnames = cn(styles.item({ columnSpan, columnStart, columnEnd, rowSpan, rowStart, rowEnd, align }))
    const Component = asChild ? Slot : 'div'

    return <Component className={classnames}>{children}</Component>
}

Grid.Item = Item

export { Grid }
