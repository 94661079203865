import { camelCase, isString } from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'
import { useMedia } from 'react-use'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { Flex } from './flex'
import { Spacer } from './spacer'
import { Text } from '../general/text'
import { TextInput } from '../forms/textInput'
import { TextInputAutocomplete } from '../forms/textInputAutocomplete'
import { TextInputSelect } from '../forms/textInputSelect'
import { Color } from '../../styles/colors'
import { HighlightableBackgrounds } from '../../hooks/general/useTheme'
import { DateSelector } from '../forms/dateSelector'
import { TextLineLoader } from '../loaders/textLineLoader'

type ColumnProp = React.CSSProperties['gridTemplateColumns']
type RowsProp = React.CSSProperties['gridTemplateRows']

export interface ListCustomNodeProps {
    type: 'custom'
    alignRight?: boolean
    skipHeader?: boolean
    node: React.ReactNode
    rightSide?: React.ReactNode
    helpNode?: any
}

export interface ListSeparatorProps {
    key: string
    type: 'separator'
    alignRight?: boolean
    rightSide?: React.ReactNode
    node: React.ReactNode
    helpNode?: any
}

export interface ListTextInputProps extends Omit<React.ComponentProps<typeof TextInput>, 'placeholder' | 'isSimple'> {
    type: 'input'
    shouldHide?: boolean
    alignRight?: boolean
    placeholder?: string
    rightSide?: React.ReactNode
    helpNode?: any
}

export interface ListTextDropdown
    extends Omit<React.ComponentProps<typeof TextInputSelect>, 'placeholder' | 'isSimple'> {
    type: 'select'
    shouldHide?: boolean
    alignRight?: boolean
    rightSide?: React.ReactNode
    placeholder?: string
    noClear?: boolean
    helpNode?: any
}

export interface AutocompleteDropdown
    extends Omit<React.ComponentProps<typeof TextInputAutocomplete>, 'placeholder' | 'isSimple'> {
    type: 'autocomplete'
    shouldHide?: boolean
    alignRight?: boolean
    placeholder?: string
    rightSide?: React.ReactNode
    helpNode?: any
}

export interface DateSelectorProps extends Omit<React.ComponentProps<typeof DateSelector>, 'placeholder' | 'isSimple'> {
    type: 'dateSelect'
    shouldHide?: boolean
    alignRight?: boolean
    placeholder?: string
    rightSide?: React.ReactNode
    helpNode?: any
}

export type TListItem =
    | string
    | ListTextInputProps
    | ListTextDropdown
    | ListSeparatorProps
    | AutocompleteDropdown
    | DateSelectorProps
    | ListCustomNodeProps
    | undefined

const renderItem = (value: TListItem, fieldID?: string, label?: string, wrap?: boolean) => {
    if (!value) return <span />
    if (typeof value === 'string' || typeof value === 'number') return <Text noWrap={!wrap}>{value}</Text>
    if ('type' in value) {
        switch (value.type) {
            case 'separator':
                return null
            case 'custom':
                return value.node
            case 'input': {
                const { placeholder, ...props } = value
                return (
                    <TextInput
                        {...props}
                        id={fieldID}
                        cy={value.cy === undefined ? camelCase(label) : value.cy}
                        placeholder={placeholder === undefined ? '-' : placeholder}
                        isSeamless
                    />
                )
            }
            case 'dateSelect': {
                const { placeholder, ...props } = value
                // cy={camelCase(label)}
                // placeholder={placeholder || '-'}
                return <DateSelector {...props} />
            }
            case 'autocomplete': {
                const { placeholder, ...props } = value
                return (
                    <TextInputAutocomplete
                        {...props}
                        cy={camelCase(label)}
                        placeholder={placeholder || '-'}
                        isSeamless
                    />
                )
            }
            case 'select': {
                const { placeholder, ...props } = value
                return <TextInputSelect cy={camelCase(label)} {...props} placeholder={placeholder || '-'} isSeamless />
            }
            default:
                return null
        }
    }
}

type ListRowGap = 'smaller' | 'medium' | 'tiny'
export const List: React.FC<{
    template?: React.CSSProperties['gridTemplateColumns']
    items: { [key: string]: TListItem }
    switchToRowsAt?: number
    cellHorizontalTemplate?: ColumnProp
    cellVerticalTemplate?: RowsProp
    emphasizeLabels?: boolean
    isLoading?: boolean
    rowGap?: ListRowGap
    alignRight?: boolean
    maxLoaderWidth?: number
    background: HighlightableBackgrounds
    cy?: string
    wrap?: boolean
}> = ({
    template,
    items,
    cy,
    switchToRowsAt,
    alignRight,
    isLoading,
    maxLoaderWidth,
    rowGap = 'tiny',
    background,
    wrap,
    cellHorizontalTemplate,
    emphasizeLabels,
    cellVerticalTemplate
}) => {
    const fieldIDLinker = useRef(camelCase(uuid()))
    const shouldBeVertical = useMedia(switchToRowsAt ? `(max-width: ${switchToRowsAt}px)` : '')
    const colors = useMemo((): {
        label: Color
        value: Color
    } => {
        if (background === 'side.background')
            return {
                label: 'side.text.subtlerI',
                value: 'side.text'
            }
        if (background === 'front.subtleDanger.background')
            return {
                label: 'front.subtleDanger.text',
                value: 'front.text'
            }
        if (background === 'floating.background')
            return {
                label: 'floating.text.strongerI',
                value: 'floating.text'
            }
        return {
            label: 'front.text.subtlerI',
            value: 'front.text'
        }
    }, [background])

    const renderLabel = useCallback(
        (item: TListItem, text: string) => {
            if (typeof item === 'string')
                return (
                    <Flex align="center">
                        <Text noWrap color={emphasizeLabels ? colors.value : colors.label}>
                            {text}
                        </Text>
                        <Spacer width={10} height={1} />
                    </Flex>
                )
            if (item?.type == 'custom' && item.skipHeader) {
                return null
            }
            return (
                <Flex align="center" justify={item?.alignRight ? 'flex-end' : 'flex-start'}>
                    {item?.alignRight && <Spacer width={10} height={1} />}
                    <Flex justify="space-between" grow>
                        <Text noWrap color={emphasizeLabels ? colors.value : colors.label}>
                            {text}
                            {item?.helpNode}
                        </Text>
                        {item?.rightSide}
                    </Flex>
                    {!item?.alignRight && <Spacer width={10} height={1} />}
                </Flex>
            )
        },
        [colors, emphasizeLabels]
    )

    const renderedItems = useMemo(() => {
        return Object.keys(items).map((la, i) => {
            const item = items[la] as TListItem
            if (!item) return null
            if (typeof item !== 'string') {
                if (item.type === 'separator')
                    return (
                        <Cell
                            key={item.key}
                            shouldBeVertical={shouldBeVertical}
                            cellHorizontalTemplate={cellHorizontalTemplate}
                            cellVerticalTemplate={cellVerticalTemplate}
                        >
                            <SeparatorCell>{item.node}</SeparatorCell>{' '}
                        </Cell>
                    )
            }

            if ((items[la] as any).shouldHide) return null

            return (
                <Cell
                    key={la}
                    shouldBeVertical={shouldBeVertical}
                    cellHorizontalTemplate={cellHorizontalTemplate}
                    cellVerticalTemplate={cellVerticalTemplate}
                    className="list-item"
                >
                    <Label htmlFor={`${fieldIDLinker.current}-${i}`} colors={colors}>
                        {renderLabel(items[la], la)}
                    </Label>
                    <CellInner alignRight={alignRight || (items[la] as any).alignRight} className="list-value">
                        {isLoading ? (
                            <TextLineLoader height={19} maxWidth={maxLoaderWidth} />
                        ) : (
                            <>
                                {isString(renderItem(items[la], undefined, undefined, wrap)) ? (
                                    <Text>{renderItem(items[la], `${fieldIDLinker.current}-${i}`, la, wrap)}</Text>
                                ) : (
                                    renderItem(items[la], `${fieldIDLinker.current}-${i}`, la, wrap)
                                )}
                            </>
                        )}
                    </CellInner>
                </Cell>
            )
        })
    }, [
        items,
        shouldBeVertical,
        cellHorizontalTemplate,
        cellVerticalTemplate,
        maxLoaderWidth,
        renderLabel,
        alignRight,
        isLoading,
        colors,
        wrap
    ])

    return (
        <Row
            template={template}
            count={Object.keys(items).length}
            data-cy={cy}
            shouldBeVertical={shouldBeVertical}
            rowGap={rowGap}
        >
            {renderedItems}
        </Row>
    )
}

const Label = styled.label<{
    colors: {
        label: Color
        value: Color
    }
}>`
    white-space: nowrap;
    color: ${(p) => p.color};
    position: relative;
    @media print {
        opacity: 0.5;
    }
`
const CellInner = styled.div<{ alignRight?: boolean }>`
    display: flex;
    width: 100%;
    flex-basis: 100%;

    ${(p) =>
        p.alignRight &&
        css`
            justify-content: flex-end;
        `}
`

type test = React.CSSProperties['gridTemplateColumns']
const Row = styled.div<{
    template?: test
    count: number
    shouldBeVertical?: boolean
    rowGap?: ListRowGap
}>`
    display: grid;
    margin: 0 0px;
    width: 100%;
    grid-column-gap: 30px;
    grid-template-rows: min-content;
    grid-row-gap: ${(p) => {
        if (p.rowGap === 'tiny') return 3
        if (p.rowGap === 'smaller') return 6
        return 3
    }}px;

    ${(p) => {
        if (p.shouldBeVertical)
            return css`
                grid-template-columns: 100%;
                margin: -5px 0;
                grid-template-rows: minmax(0, min-content);
            `
        if (p.template)
            return css`
                grid-template-columns: ${p.template};
            `
        return css`
            grid-template-columns: repeat(${p.count}, minmax(0, 1fr));
        `
    }};

    @media print {
        margin: 0;
        width: 100%;
    }
`
const Cell = styled.div<{
    shouldBeVertical?: boolean
    cellHorizontalTemplate?: ColumnProp
    cellVerticalTemplate?: RowsProp
}>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    margin-top: 2px;
    &:last-child ${Label} {
        margin-bottom: 0 !important;
    }

    ${(p) =>
        p.shouldBeVertical &&
        p.cellHorizontalTemplate &&
        css`
            display: grid;
            grid-template-columns: ${p.cellHorizontalTemplate ? p.cellHorizontalTemplate : 'min-content min-content'};
            grid-template-rows: min-content;
        `}
`

const SeparatorCell = styled.div`
    grid-column: span 2;
`
