import { Page, Container, Flex, Grid, Loader } from '@/design-system'
import { RTKQuery } from '@/services/common'
import { MAPI_PARTNERS_ROOT, getPartner, updatePartner, useGetPartner } from '@/services/merchant-api'
import { FormProvider } from 'react-hook-form'
import { Form, redirect, useLocation, useNavigate, useNavigation, useParams, useSubmit } from 'react-router-dom'
import { PartnerSchema, usePartnerForm } from '@/features/partners'
import { PageHeader, SaveCard, CheckboxGroup, FormInput } from '@/ui'

export const loader = async ({ request, params }) => {
    const { id } = params
    return await RTKQuery.get(getPartner, request, `${MAPI_PARTNERS_ROOT}/${id}`)
}

export const action = async ({ request, params }) => {
    const formData = await request.formData()
    const { id } = params

    const data = Object.fromEntries(formData)

    const { referrer, ...values } = data

    const body = { url: `${MAPI_PARTNERS_ROOT}/${id}`, data: values }
    await RTKQuery.patch(updatePartner, body)

    const redirectTo = referrer ? referrer : '/more/partners'

    return redirect(redirectTo)
}

const PartnerEdit = () => {
    const navigate = useNavigate()
    const navigation = useNavigation()
    const location = useLocation()

    const { id } = useParams()
    const { data } = useGetPartner(`${MAPI_PARTNERS_ROOT}/${id}`)
    const form = usePartnerForm({
        name: data?.name ? data?.name : '',
        technical: !!data?.technical,
        support: !!data?.support,
        referral: !!data?.referral
    })
    const submit = useSubmit()

    const technical = form.register('technical')
    const onTechnicalChange = (checked: boolean) => {
        form.setValue('technical', checked)
    }

    const support = form.register('support')
    const onSupportChange = (checked: boolean) => {
        form.setValue('support', checked)
    }

    const referral = form.register('support')
    const onReferralChange = (checked: boolean) => {
        form.setValue('referral', checked)
    }

    const onBack = () => {
        navigate(-1)
    }

    const onSubmit = form.handleSubmit((values: PartnerSchema) => {
        const valuesWithReferrer = {
            ...values,
            referrer: location?.state?.referrer ? location.state.referrer : '/more/partners'
        }

        submit(valuesWithReferrer, { method: 'patch' })
    })

    const isLoading = navigation.state === 'loading'
    const errorCount = Object.keys(form.formState.errors).length

    return (
        <Loader isLoading={isLoading}>
            <Page pb="normal">
                <PageHeader title="Edit partner" onBack={onBack} />
                <Flex justify="center" align="center" grow="1">
                    <Container size="lg">
                        <FormProvider {...form}>
                            <Form onSubmit={onSubmit}>
                                <SaveCard>
                                    <SaveCard.Body>
                                        <SaveCard.Title>Partner details</SaveCard.Title>
                                        <SaveCard.Fields>
                                            <Grid columns="3" gap="3">
                                                <FormInput>
                                                    <FormInput.Label name="name">Name</FormInput.Label>
                                                    <FormInput.Input
                                                        testid="input-partner-name"
                                                        name="name"
                                                        defaultValue={data?.name}
                                                    />
                                                </FormInput>
                                                <CheckboxGroup label="Type">
                                                    <CheckboxGroup.Checkbox
                                                        testid="checkbox-partner-technical"
                                                        name="technical"
                                                        label="Technical"
                                                        defaultChecked={form.getValues('technical')}
                                                        ref={technical.ref}
                                                        onChange={onTechnicalChange}
                                                    />
                                                    <CheckboxGroup.Checkbox
                                                        testid="checkbox-partner-support"
                                                        name="support"
                                                        label="Support"
                                                        defaultChecked={form.getValues('support')}
                                                        ref={support.ref}
                                                        onChange={onSupportChange}
                                                    />
                                                    <CheckboxGroup.Checkbox
                                                        testid="checkbox-partner-referral"
                                                        name="referral"
                                                        label="Referral"
                                                        defaultChecked={form.getValues('referral')}
                                                        ref={referral.ref}
                                                        onChange={onReferralChange}
                                                    />
                                                </CheckboxGroup>
                                            </Grid>
                                        </SaveCard.Fields>
                                    </SaveCard.Body>
                                    <SaveCard.Footer testid="card-partner-footer" errorCount={errorCount} />
                                </SaveCard>
                            </Form>
                        </FormProvider>
                    </Container>
                </Flex>
            </Page>
        </Loader>
    )
}

PartnerEdit.loader = loader
PartnerEdit.action = action

export { PartnerEdit }
