import React, { useMemo, useState } from 'react'
import { PageHeader } from '../../components/layout/pageHeader'
import { VouchersTable } from '@/features/reconciliation'
import { useAuth } from '@/hooks/auth/useAuth'
import { Flex } from '@/components/layout/flex'
import { Text } from '../../components/general/text'
import { TableFuzzySearch } from '@/components/layout/tableFuzzySearch'
// eslint-disable-next-line
import {
    appendActions,
    getVoucherCols,
    getVoucherRows
} from '@/features/reconciliation/components/vouchers/vouchers-table/helpers'
import { useGetAccounts, useGetVouchers } from '@/services/reconciliation'
import { TableRow } from '@/components/tables/table'

export const UnreconciledVouchers: React.FC = () => {
    const { user } = useAuth()
    const isFinanceAgent = user?.['https://clearhaus.com/app_metadata'].roles.includes('finance')

    const { isLoading: vouchersLoading, data: vouchers } = useGetVouchers()
    const { isLoading: accountsLoading, data: accounts } = useGetAccounts()
    const [filteredRows, setFilteredRows] = useState<TableRow[]>([])

    const { cols, rows } = useMemo(() => {
       return appendActions(getVoucherCols(), getVoucherRows(vouchers?.items, accounts?.items))
    }, [vouchers, accounts])

    const leftSideMemo = useMemo(() => {
        return (
            <TableFuzzySearch
                headers={cols}
                queryParamName="reconciliation-query"
                rows={rows}
                setFilteredRows={setFilteredRows}
                ignoreHeaderWithText="Actions"
            />
        )
    }, [rows, setFilteredRows, cols])
    
    return (
        <>
            <PageHeader
                noBottomBorder
                title="Unreconciled vouchers"
                subtitle={filteredRows.length}
                leftSideMemo={leftSideMemo}
            />
            {isFinanceAgent ?
                <VouchersTable
                    rows={filteredRows}
                    cols={cols}
                    isLoading={vouchersLoading || accountsLoading}
                />
            :
                <Flex align="center" justify="center" grow column>
                    <Text bold color="back.text.strongerII">
                        Only finance employees can use this tool
                    </Text>
                </Flex>
            }
        </>
    )
}
