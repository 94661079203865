import { VariantProps, cva } from 'class-variance-authority'

export type TableStyles = VariantProps<typeof styles.table>
export type RowStyles = VariantProps<typeof styles.row>
export type HeadStyles = VariantProps<typeof styles.head>
export type BodyStyles = VariantProps<typeof styles.body>
export type CellStyles = VariantProps<typeof styles.cell>

export const styles = {
    table: cva([
        'table-auto',
        'min-w-full',
        'divide-y-2',
        'divide-neutral-200',
        'rounded-lg',
        'text-sm',
        'dark:text-neutral-200',
        'border-spacing-0',
        'dark:bg-gray-900'
    ]),
    row: cva([
        'border-none',
        'odd:bg-neutral-50',
        'hover:bg-neutral-100',
        'dark:odd:bg-gray-800',
        'dark:hover:bg-gray-700'
    ]),
    head: cva(
        [
            'whitespace-nowrap',
            'px-4',
            'py-1',
            'text-left',
            'text-[9px]',
            'font-medium',
            'text-neutral-400',
            'bg-white',
            'uppercase',
            'dark:bg-gray-900'
        ],
        {
            variants: {
                justify: {
                    start: 'text-left',
                    end: 'text-right',
                    center: 'text-center'
                }
            }
        }
    ),
    body: cva('border-none divide-y'),
    cell: cva('whitespace-nowrap px-4 py-2 first:rounded-l-lg last:rounded-r-lg', {
        variants: {
            justify: {
                start: 'text-left',
                end: 'text-right',
                center: 'text-center'
            }
        }
    })
}
