import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { Icon } from '../icons/icon'
import { ButtonInset } from '../buttons/buttonInset'
import { LinkButton } from '../buttons/linkButton'
import { CardInsetType } from './cardInset'
import { LoaderView } from '../loaders/loader'
import { Text } from '../general/text'
import { Color } from '../../styles/colors'
import { Theme } from '../../hooks/general/useTheme'

export const Card: React.FC<{
    title?: React.ReactNode | string
    subtitle?: React.ReactNode | string
    children?: any
    frameless?: boolean
    higher?: boolean
    stretch?: boolean
    grow?: boolean
    backTo?: any
    background?: Color
    onBack?: () => void
    cy?: string
    className?: string
    headerInsetType?: CardInsetType
    rightSideMemo?: React.ReactNode
    leftSideMemo?: React.ReactNode
    hasFloatingHeader?: boolean
    loading?: boolean
    shadowLess?: boolean
    paddingLess?: boolean
    overflowX?: boolean
    showEditable?: boolean
}> = ({
    title,
    subtitle,
    stretch,
    className = '',
    backTo,
    children,
    leftSideMemo,
    headerInsetType = 'regular',
    paddingLess,
    rightSideMemo,
    cy,
    background = 'front.background',
    frameless,
    higher,
    hasFloatingHeader,
    grow,
    loading,
    shadowLess,
    overflowX,
    showEditable
}) => {
    const backButton = useMemo(() => {
        if (!backTo) return
        return (
            <LinkButton to={backTo} color="front.text">
                <ButtonInset negativeMargin="left">
                    <Icon type="navigationBackArrow" />
                </ButtonInset>
            </LinkButton>
        )
    }, [backTo])

    const textColor = useMemo(() => {
        return 'front.text' as const
    }, [])

    const borderColor = useMemo((): keyof Theme => {
        if (background === 'back.background.strongerI') return 'back.border.strongerI'
        if (background === 'front.subtleInfo.background') return 'front.subtleInfo.border'
        if (background === 'front.subtleDanger.background') return 'front.subtleDanger.border'
        if (background === 'front.subtleAccent.background') return 'front.subtleAccent.border'
        if (background === 'front.subtleSuccess.background') return 'front.subtleSuccess.border'
        if (background === 'front.subtleDanger.background.strongerI') return 'front.subtleDanger.border'
        return 'front.border'
    }, [background])

    return (
        <Wrapper
            frameless={frameless}
            higher={higher}
            background={background}
            textColor={textColor}
            stretch={stretch}
            grow={grow}
            data-cy={cy}
            overflowX={overflowX}
            shadowLess={shadowLess}
            className={`PREVENT_MODAL_CLOSE ${className}`}
        >
            {title && (
                <>
                    <CardHeader borderColor={borderColor} paddingLess={paddingLess}>
                        {backButton}
                        {leftSideMemo}
                        <Title hasBackButton={backButton !== undefined}>
                            <Text bold>{title}</Text>
                            {subtitle ? <Text>{subtitle}</Text> : null}
                        </Title>
                        {rightSideMemo}
                    </CardHeader>
                    {children && (
                        <>
                            {loading ? (
                                <LoaderHolder background={background}>
                                    <LoaderView overBackground="front.background" />
                                </LoaderHolder>
                            ) : null}
                        </>
                    )}
                </>
            )}
            {children && <Content hasFloatingHeader={hasFloatingHeader}>{children}</Content>}
            {showEditable && (
                <Editable>
                    <Icon type="editPen" />
                </Editable>
            )}
        </Wrapper>
    )
}

const Editable = styled.div`
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-size: 10px;
    padding: 0px 5px;
    text-transform: uppercase;
    color: ${(p) => p.theme['front.text.subtlerII']};
    border-radius: 0 0 0 10px;
    font-weight: 500;
`

const LoaderHolder = styled.div<{ background?: Color }>`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 54px);
    top: 54px;
    box-sizing: border-box;
    border-radius: 0 0 12px 12px;
    z-index: 1;
`

export const CardStyles: {
    [key: string]: any
} = {
    normal: css`
        box-shadow: ${(p) => p.theme.cardsShadow};
        border-radius: 8px;
    `,
    higher: css`
        box-shadow: 0px 17px 20px rgba(0, 15, 30, 0.075), 0px 1px 2px rgba(0, 15, 30, 0.15);
        border-radius: 16px 16px;
    `
}

const Title = styled.div<{ hasBackButton?: boolean }>`
    ${(p) =>
        p.hasBackButton &&
        css`
            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            top: 50%;
            z-index: 0;
        `}
`

export const CardHeaderSeparator = styled.div`
    height: 1px;
    width: 100%;
`

export const CardHeader = styled.div<{
    paddingLess?: boolean
    interactive?: boolean
    isClosed?: boolean
    borderColor: keyof Theme
}>`
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    padding: 15px 30px;
    margin-bottom: 0px;
    box-sizing: border-box;

    ${(p) =>
        p.paddingLess &&
        css`
            padding: 0;
        `}

    justify-content: flex-start;

    @media print {
        align-items: baseline;
    }

    &:after {
        content: '';
        bottom: 0;
        left: 25px;
        right: 20px;
        width: calc(100% - 50px);
        height: 1px;
        position: absolute;
        background-color: ${(p) => p.theme[p.borderColor]};
    }

    ${(p) =>
        p.interactive &&
        css`
            margin-bottom: 0;
            padding-bottom: 0;
            cursor: pointer;
            background-color: ${(p) => p.theme['front.background']};
            border-radius: 12px 12px 0 0;
            border-bottom: 1px solid ${p.theme['front.background.strongerI']};

            &:hover {
                background-color: ${(p) => p.theme['front.background.strongerI']};
                border-bottom: 1px solid ${p.theme['front.background.strongerII']};
            }

            &:active {
                background-color: ${(p) => p.theme['front.background.strongerII']};
                border-bottom: 1px solid ${p.theme['front.background.strongerIII']};
            }

            &:after {
                display: none;
            }

            ${p.isClosed &&
            css`
                border-bottom: 1px solid transparent;
            `}
        `}
    ${(p) =>
        p.isClosed &&
        css`
            border-radius: 10px;
        `}
`

// ${CardStyles.whiteBackground};
const Wrapper = styled.div<{
    frameless?: boolean
    higher?: boolean
    grow?: boolean
    stretch?: boolean
    hasFloatingHeader?: boolean
    background?: Color
    shadowLess?: boolean
    textColor?: Color
    overflowX?: boolean
}>`
    background: ${(p) => p.theme[p.background || 'front.background']};
    border-radius: 12px;
    box-shadow: ${(p) => p.theme.cardsShadow};

    display: flex;
    flex-direction: column;
    position: relative;
    color: ${(p) => p.theme[p.textColor || 'front.text']};
    align-items: stretch;

    ${(p) =>
        p.hasFloatingHeader &&
        css`
            overflow: hidden;
        `}
    ${(p) =>
        p.stretch &&
        css`
            align-self: stretch;
        `}
    ${(p) =>
        p.grow &&
        css`
            flex-grow: 1;
        `}
        ${(p) =>
        p.frameless &&
        css`
            background: transparent;
            padding: 0;
            border-radius: 0;
            box-shadow: none;
        `}
        ${(p) => p.higher && CardStyles.higher};
    /* ${(p) => p.overflowX && css``} */

    ${(p) =>
        p.shadowLess &&
        css`
            box-shadow: none;
        `}
    @media print {
        margin: 0 !important;
        background-color: #fff !important;
        box-shadow: none !important;
        padding: 0 !important;
    }
`

const Content = styled.div<{ hasFloatingHeader?: boolean }>`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;

    ${(p) =>
        p.hasFloatingHeader &&
        css`
            margin-top: -10px;
        `}
    overflow-x: auto;
    overscroll-behavior-x: none;
`
