import React from 'react'
import styled, { css } from 'styled-components'
import { Flex } from './flex'

export type TableBubbleType = 'subtle' | 'outlined' | 'important' | 'verySubtle'
export const TableBubble: React.FC<{ isZero?: boolean; type?: TableBubbleType; children?: React.ReactNode }> = ({
    children,
    isZero,
    type
}) => {
    return (
        <Flex>
            <Detail isZero={isZero} type={type}>
                {children}
            </Detail>
        </Flex>
    )
}

const Detail = styled.div<{ isZero?: boolean; type?: TableBubbleType }>`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 11px;

    font-weight: 500;
    padding: 0 7px;
    line-height: 18px;
    margin: 0 -7px;
    border-radius: 9px;
    letter-spacing: 0.1px;

    ${(p) => {
        switch (p.type) {
            case 'important':
                return css`
                    background-color: ${p.theme['front.background.strongerII']};
                    border: 1px solid ${p.theme['front.background.strongerII']};
                    font-weight: 500;
                    color: ${p.theme['front.text']};
                `
            case 'outlined':
                return css`
                    border: 1px solid ${p.theme['front.border']};
                    border: 1px solid ${p.theme['front.background.strongerI']};
                    background: ${p.theme['front.background']};
                `
            case 'verySubtle':
                return css`
                    border: 1px solid ${p.theme['front.border']};
                    border: 1px solid ${p.theme['front.background.strongerI']};
                    color: ${p.theme['front.text.subtlerI']};
                    background: ${p.theme['front.background']};
                `
            default:
                return css`
                    color: ${p.theme['front.text']};
                    background-color: ${p.theme['front.background.strongerI']};
                    border: 1px solid ${p.theme['front.background.strongerI']};
                    /* border: 1px solid ${p.theme['front.background.strongerI']};
                    background-color: ${p.theme['front.background.strongerI']}; */
                `
        }
    }}
    ${(p) =>
        p.isZero &&
        css`
            font-weight: 400;
            background-color: transparent !important;
            border: 1px solid transparent;
            color: ${(p) => p.theme['front.text.subtlerII']} !important;
        `}
`
