import { RootState } from '@/store'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const reducerPath = 'reconciler-api'
const tagTypes = ['accounts', 'postings', 'vouchers']

export const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_RECONCILER_ROOT,
    prepareHeaders: (headers, { getState }) => {
        const { auth } = getState() as RootState
        const { token } = auth

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }

        return headers
    }
})

export const api = createApi({
    reducerPath,
    baseQuery: baseQuery,
    tagTypes,
    endpoints: () => ({})
})
