import { SimpleButton } from '@/components/buttons/simpleButton'
import React from 'react'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { useUnreconcilePostingActions, useUnreconcileVoucherActions } from './use-unreconcile-actions'

interface UnreconcileActionProps {
    type: "posting" | "voucher"
}

export const UnreconcileAction: React.FC<UnreconcileActionProps> = (props) => {
    const { type } = props
    const { isLoading: isLoadingPosting, unreconcile: unreconcilePosting } = useUnreconcilePostingActions()
    const { isLoading: isLoadingVoucher, unreconcile: unreconcileVoucher } = useUnreconcileVoucherActions()

    const watcher = (isLoadingPosting || isLoadingVoucher) ? 'started' : undefined

    const handleUnreconcile = async () => {
        if (type === "posting") await unreconcilePosting()
        if (type === "voucher") await unreconcileVoucher()
    }

    return (
        <SimpleButton background="front.danger.color" onClick={handleUnreconcile}>
            <ButtonLoadingCover background="front.background" watcher={watcher}>
                <ButtonInset>Unreconcile</ButtonInset>
            </ButtonLoadingCover>
        </SimpleButton>
    )
}
