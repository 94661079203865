import { Card, Flex, Text } from '@/design-system'
import React from 'react'
import { PartnerDetails } from './partner-details'

interface DetailsSectionProps {
    name?: string
    technical?: boolean
    support?: boolean
    referral?: boolean
    onEditClick: () => void
}

export const DetailsSection: React.FC<DetailsSectionProps> = (props) => {
    const { name, technical, support, referral, onEditClick } = props

    return (
        <Flex direction="column" gap="3">
            <Text weight="medium">Partner details</Text>
            <Card>
                <Card.Body>
                    <PartnerDetails
                        name={name}
                        technical={technical}
                        support={support}
                        referral={referral}
                        onEditClick={onEditClick}
                    />
                </Card.Body>
            </Card>
        </Flex>
    )
}
