import { SimpleButton } from '@/components/buttons/simpleButton'
import React from 'react'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { ButtonLoadingCover } from '@/components/buttons/buttonLoadingCover'
import { useReconcilePostingActions, useReconcileVoucherActions } from './use-reconcile-actions'

interface ReconcileActionProps {
    type: "posting" | "voucher"
}

export const ReconcileAction: React.FC<ReconcileActionProps> = (props) => {
    const { type } = props
    const { isLoading: isLoadingPosting, reconcile: reconcilePosting } = useReconcilePostingActions()
    const { isLoading: isLoadingVoucher, reconcile: reconcileVoucher } = useReconcileVoucherActions()

    const watcher = (isLoadingPosting || isLoadingVoucher) ? 'started' : undefined

    const handleReconcile = async () => {
        if (type === "posting") await reconcilePosting()
        if (type === "voucher") await reconcileVoucher()
        
    }

    return (
        <SimpleButton background="front.accent.color" onClick={handleReconcile}>
            <ButtonLoadingCover background="front.background" watcher={watcher}>
                <ButtonInset>Reconcile</ButtonInset>
            </ButtonLoadingCover>
        </SimpleButton>
    )
}
