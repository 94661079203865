import { VariantProps, cva } from 'class-variance-authority'

export type Styles = VariantProps<typeof styles>

export const styles = cva(
    'inline-flex items-center justify-center text-md leading-snug focus-visible:outline-none whitespace-nowrap',
    {
        variants: {
            variant: {
                default: 'rounded-xl shadow-md focus:!shadow-md',
                link: 'bg-transparent hover:underline',
                ghost: 'bg-transparent rounded-xl ',
                solid: 'rounded-xl',
                outline: 'bg-transparent rounded-xl focus:!shadow-md',
                soft: 'rounded-xl focus:!shadow-sm'
            },
            size: {
                default: 'h-9 px-3',
                sm: 'h-8 px-3',
                lg: 'h-11 px-4',
                none: 'h-0 px-0'
            },
            color: {
                primary: '',
                neutral: '',
                danger: '',
                white: ''
            },
            width: {
                auto: 'w-auto',
                full: 'w-full'
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
            color: 'primary',
            width: 'auto'
        },
        compoundVariants: [
            {
                variant: 'default',
                color: 'primary',
                class: 'bg-primary text-white hover:bg-primary-700 shadow-primary-900/20 focus:!shadow-primary-900/20'
            },
            {
                variant: 'default',
                color: 'danger',
                class: 'bg-danger text-white hover:bg-danger-700 shadow-danger-900/20 focus:!shadow-danger-900/20'
            },
            {
                variant: 'default',
                color: 'white',
                class: 'bg-white text-primary hover:bg-neutral-50 shadow-neutral-300/20 focus:!shadow-primary-300/20'
            },
            {
                variant: 'link',
                color: 'primary',
                class: 'text-primary hover:text-primary-700'
            },
            {
                variant: 'link',
                color: 'danger',
                class: 'text-danger hover:text-danger-700'
            },
            {
                variant: 'ghost',
                color: 'primary',
                class: 'text-primary hover:bg-primary-100'
            },
            {
                variant: 'ghost',
                color: 'neutral',
                class: [
                    'text-black',
                    'hover:bg-neutral-200',
                    'dark:text-neutral-200',
                    'dark:bg-neutral-200/5',
                    'dark:hover:bg-neutral-200/10'
                ]
            },
            {
                variant: 'solid',
                color: 'primary',
                class: ['text-primary-600', 'bg-primary-50', 'hover:bg-primary-100', 'dark:bg-primary-500/10']
            },
            {
                variant: 'solid',
                color: 'neutral',
                class: [
                    'text-black',
                    'bg-neutral-200',
                    'hover:bg-neutral-300',
                    'dark:bg-neutral-200/5',
                    'dark:hover:bg-neutral-200/20',
                    'dark:text-neutral-200'
                ]
            },
            {
                variant: 'outline',
                color: 'primary',
                class: [
                    'text-primary',
                    'border',
                    'border-primary',
                    'hover:bg-primary-50',
                    'dark:border-primary/10',
                    'dark:hover:bg-primary-500/10'
                ]
            },
            {
                variant: 'outline',
                color: 'neutral',
                class: [
                    'text-black',
                    'border',
                    'border-neutral-300',
                    'hover:bg-neutral-100',
                    'dark:border-neutral-200/30',
                    'dark:hover:bg-neutral-200/5',
                    'dark:text-neutral-200'
                ]
            },
            {
                variant: 'soft',
                color: 'neutral',
                class: [
                    'text-black',
                    'bg-neutral-500/5',
                    'hover:bg-neutral-500/10',
                    'dark:text-white',
                    'dark:bg-white/5',
                    'dark:hover:bg-white/10'
                ]
            }
        ]
    }
)
