import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { Table, TableRow } from '@/components/tables/table'
import { Account, Posting } from '@/services/reconciliation'
import React, { useMemo, useState } from 'react'
import { getPostingCols, getPostingRows, prependChecks } from '../postings/postings-table/helpers'
import { Spacer } from '@/components/layout/spacer'
import { Flex } from '@/components/layout/flex'
import styled from 'styled-components'
import { NoteSection } from './note-section'
import { ReconcileAction } from './reconcile-action'
import { TableFuzzySearch } from '@/components/layout/tableFuzzySearch'

interface ListPostingSectionProps {
    postings: Posting[]
    accounts: Account[]
}

export const ListPostingSection: React.FC<ListPostingSectionProps> = (props) => {
    const { postings, accounts } = props
    const [filteredRows, setFilteredRows] = useState<TableRow[]>([])
    
    const { cols, rows } = useMemo(() => {
        return prependChecks(getPostingCols(), getPostingRows(postings, accounts))
    }, [postings, accounts])

    const fuzzySearch = useMemo(() => {
        return (
            <TableFuzzySearch
                headers={cols}
                queryParamName="reconciliation-query"
                rows={rows}
                setFilteredRows={setFilteredRows}
                ignoreHeaderWithText="Actions"
            />
        )
    }, [rows, setFilteredRows, cols])

    return (
        <Card higher background="front.highlights">
            <TopPart>
                <CardInset>
                    {fuzzySearch}
                </CardInset>
            </TopPart>
            <ScrollingSide>
                <CardInset>
                    <Table
                        loaderRows={5}
                        rows={filteredRows}
                        cols={cols}
                        background="front.background"
                        columnLayout="56px min-content 100px min-content 2fr 3fr 1fr 1fr 0.5fr min-content"
                        emptyText="No unreconciled postings found."
                    />
                    <Spacer height={20} />
                </CardInset>
            </ScrollingSide>
               
            <BottomPart>
                <CardInset>
                    <NoteSection />
                <Spacer width={20} />
                <Flex align="flex-end" column>
                    <ReconcileAction type="voucher" />
                </Flex>
                </CardInset>
            </BottomPart>
        </Card>
    )
}

const ScrollingSide = styled.div`
    overflow: auto;
    flex-shrink: 1;
    flex-grow: 1;
    padding: 10px;
    height: 400px;
    box-sizing: border-box;
    overscroll-behavior-y: none;
`

const TopPart = styled.div`
    flex-grow: 0;
`

const BottomPart = styled.div`
    z-index: 1000;
    flex-shrink: 0;
    width: calc(100%);
    background-color: ${(p) => p.theme['front.background']};
    border-top: 1px solid ${(p) => p.theme['back.border']};
    border-radius: 0 0 16px 16px;
`
