import { Badge, Button, Flex, Text } from '@/design-system'
import React from 'react'

interface PartnerDetailsProps {
    name?: string
    technical?: boolean
    support?: boolean
    referral?: boolean
    onEditClick?: () => void
}

export const PartnerDetails: React.FC<PartnerDetailsProps> = (props) => {
    const { name, technical, support, referral, onEditClick } = props

    const nameString = name ? name : '-'
    const isTypeAvailable = technical || support || referral

    return (
        <Flex justify="between" align="center">
            <Flex direction="column" gap="3">
                <Text weight="medium" lineHeight="none">
                    {nameString}
                </Text>
                {isTypeAvailable ? (
                    <Flex gap="3">
                        {technical && <Badge>Technical</Badge>}
                        {support && <Badge>Support</Badge>}
                        {referral && <Badge>Referral</Badge>}
                    </Flex>
                ) : (
                    <Text color="neutral">-</Text>
                )}
            </Flex>
            <Button variant="link" onClick={onEditClick}>
                Edit
            </Button>
        </Flex>
    )
}
