import { SimpleButton } from '@/components/buttons/simpleButton'
import React from 'react'
import { ButtonInset } from '@/components/buttons/buttonInset'
import { Spacer } from '@/components/layout/spacer'
import { Flex } from '@/components/layout/flex'
import { numberWithCurrency } from '@/utils'
import { Posting, Voucher } from '@/services/reconciliation'

interface LoadHigherAmountsButtonProps {
    data: Voucher | Posting
    loadHigherAmounts: boolean
    onClick: () => void
}

export const LoadHigherAmountsButton: React.FC<LoadHigherAmountsButtonProps> = (props) => {
    const { data, loadHigherAmounts, onClick } = props
    const amount = numberWithCurrency(data.currency, data.amount)
    const currency = data.currency
    const action = loadHigherAmounts ? 'Hide' : 'Load'

    return (
        <>
            <Spacer width={20} />
            <Flex align="center" column>
                <SimpleButton
                    color="side.accent.color"
                    onClick={onClick}
                >
                    <ButtonInset>
                        {action} resources with amount higher than {amount} {currency}
                    </ButtonInset>
                </SimpleButton>
            </Flex>
        </>
    )
}
