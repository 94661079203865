import { Text } from '@/components/general/text'
import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import { LoaderView } from '@/components/loaders/loader'
import {
    FormProvider,
    ListPostingSection,
    LoadHigherAmountsButton,
    ReconciliationModal,
    SingleVoucherSection,
    useReconciliationParams
} from '@/features/reconciliation'
import { useGetAccounts, useGetPostings, useGetVoucher } from '@/services/reconciliation'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ReconcileVoucher: React.FC = () => {
    const { id } = useReconciliationParams()
    const { data: voucherData, isLoading: voucherLoading, isError: voucherError } = useGetVoucher(id)
    const { data: postingsData, isLoading: postingsLoading, isError: postingsError } = useGetPostings()
    const { data: accountsData, isLoading: accountsLoading, isError: accountsError } = useGetAccounts()
    const [loadHigherAmounts, setLoadHigherAmounts] = useState(false)

    const navigate = useNavigate()

    const handleClick = useCallback(() => {
        setLoadHigherAmounts((t) => !t)
    }, [setLoadHigherAmounts])

    useEffect(() => {
        if (voucherError || postingsError || accountsError) {
            navigate(-1)
        }
    }, [navigate, accountsError, voucherError, postingsError])

    if (voucherLoading || postingsLoading || accountsLoading) {
        return <LoaderView overBackground="front.background" type="l" minHeight="200" />
    }

    if (!voucherData || !postingsData || !accountsData) {
        return <Text>No data available</Text>
    }

    return (
        <FormProvider data={'voucher'}>
            <ReconciliationModal type="voucher">
                <Flex column>
                    <SingleVoucherSection voucher={voucherData} accounts={accountsData.items} />
                    <LoadHigherAmountsButton
                        data={voucherData}
                        loadHigherAmounts={loadHigherAmounts}
                        onClick={handleClick}
                    />
                    {!voucherData.reconciled && (
                        <>
                            <Spacer width={20} />
                            <ListPostingSection
                                postings={postingsData.items
                                    .filter((p) =>
                                        loadHigherAmounts
                                            ? p.currency === voucherData.currency
                                            : p.currency === voucherData.currency && p.amount <= voucherData.amount
                                    )
                                    .sort((a, b) => {
                                        return b.amount - a.amount
                                    })}
                                accounts={accountsData.items}
                            />
                        </>
                    )}
                </Flex>
            </ReconciliationModal>
        </FormProvider>
    )
}
