import { Box, Card, Collapsible, Flex, Separator, Text } from '@/design-system'
import React from 'react'
import { IntegrationHeader } from './integration-header'
import { TransactionRules } from './transaction-rules'
import { Partner } from '@/services/merchant-api'
import { IntegrationGeneralInfo } from './integration-general-info'

interface IntegrationCardProps {
    children: React.ReactNode
    name: string
    description: string
    transactionRules: string
    partners: Partner[]
    onEditClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const IntegrationCard: React.FC<IntegrationCardProps> = (props) => {
    const { children, name, description, transactionRules, partners, onEditClick } = props

    return (
        <Collapsible>
            <Card>
                <Card.Body>
                    <Flex direction="column" gap="6">
                        <Collapsible.Trigger testid="integration-trigger">
                            <IntegrationHeader partners={partners} onEditClick={onEditClick} />
                        </Collapsible.Trigger>
                        <Collapsible.Content>
                            <Flex direction="column" gap="10">
                                <Separator />
                                <IntegrationGeneralInfo name={name} description={description} />
                                <Flex direction="column" gap="4">
                                    <Text color="neutral">Transaction rules</Text>
                                    <TransactionRules transactionRules={transactionRules} />
                                </Flex>
                                <Flex direction="column" gap="4">
                                    {children}
                                </Flex>
                            </Flex>
                        </Collapsible.Content>
                    </Flex>
                </Card.Body>
            </Card>
        </Collapsible>
    )
}
