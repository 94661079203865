import React from 'react'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { cn } from '../utils'
import { ChevronStyles, styles } from './styles'
import { ChevronDownIcon } from '@radix-ui/react-icons'

interface CollapsibleProps {
    children: React.ReactNode
    defaultOpen?: boolean
}

const Collapsible = (props: CollapsibleProps) => {
    const { children, defaultOpen } = props

    return <CollapsiblePrimitive.Root defaultOpen={defaultOpen}>{children}</CollapsiblePrimitive.Root>
}

interface TriggerProps {
    testid?: string
    children: React.ReactNode
    asChild?: boolean
}

const Trigger = React.forwardRef<React.ElementRef<typeof CollapsiblePrimitive.Trigger>, TriggerProps>((props, ref) => {
    const { testid, children, asChild } = props

    const classnames = cn(styles.trigger())

    return (
        <CollapsiblePrimitive.Trigger data-cy={testid} ref={ref} className={classnames} asChild={asChild}>
            {children}
        </CollapsiblePrimitive.Trigger>
    )
})

Trigger.displayName = 'Collapsible.Trigger'

interface ContentProps {
    children: React.ReactNode
}

const Content = React.forwardRef<React.ElementRef<typeof CollapsiblePrimitive.Content>, ContentProps>((props, ref) => {
    const { children } = props

    const classnames = cn(styles.content())

    return (
        <CollapsiblePrimitive.Content ref={ref} className={classnames}>
            {children}
        </CollapsiblePrimitive.Content>
    )
})

Content.displayName = 'Collapsible.Content'

const Chevron: React.FC<ChevronStyles> = (props) => {
    const { color } = props

    const classnames = cn(styles.chevron({ color }))

    return <ChevronDownIcon className={classnames} />
}

Collapsible.Trigger = Trigger
Collapsible.Content = Content
Collapsible.Chevron = Chevron

export { Collapsible }
