import { ModalHeader } from '@/components/modals/modalHeader'
import { ModalPage } from '@/components/layout/modalPage'
import { ModalPageInset } from '@/components/layout/modalPageInset'
import React from 'react'
import { useReconciliationParams } from '../../hooks'
import styled from 'styled-components'

interface ReconciliationModalProps {
    children: React.ReactNode
    type: "posting" | "voucher"
}

export const ReconciliationModal: React.FC<ReconciliationModalProps> = (props) => {
    const { children, type } = props
    const { id } = useReconciliationParams()

    return (
        <ModalPage title="Reconcile" pageId="Reconcile">
            <ModalHolder>
                <ModalHeader
                    title={`Reconcile ${type} ${id}`}
                    pageId="Reconcile"
                    backTo={`/more/unreconciled-${type}s`}
                />
                <ModalPageInset>{children}</ModalPageInset>
            </ModalHolder>
        </ModalPage>
    )
}

const ModalHolder = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
`
