import { Flex, Text } from '@/design-system'
import React from 'react'

interface IntegrationGeneralInfoProps {
    name: string
    description: string
}

export const IntegrationGeneralInfo: React.FC<IntegrationGeneralInfoProps> = (props) => {
    const { name, description } = props

    const nameString = name ? name : '-'
    const descriptionString = description ? description : '-'

    return (
        <Flex direction="column" gap="4">
            <Flex gap="14">
                <Flex direction="column" gap="1">
                    <Text color="neutral">Name</Text>
                    <Text testid="integration-name">{nameString}</Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text color="neutral">Description</Text>
                    <Text>{descriptionString}</Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
