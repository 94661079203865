import { createBrowserRouter, Navigate } from 'react-router-dom'
import { Main } from './Main'
import { Flex } from './components/layout/flex'
import { Spacer } from './components/layout/spacer'
import { Alerts } from './pages/Alerts/Alerts'
import { AlertModal } from './pages/Alerts/Alerts.ID'
import { AlertTagsManager } from './pages/Alerts/Alerts.ID.Tags'
import { AlertRouter } from './pages/Alerts/Alerts.IDRouter'
import { Applications } from './pages/Applications/Applications'
import { Calls } from './pages/Calls/Calls'
import { ActiveCalls } from './pages/Calls/Calls.ActiveCalls'
import { Call } from './pages/Calls/Calls.ID'
import { Queues } from './pages/Calls/Calls.Queues'
import { Disputes } from './pages/Disputes/Disputes'
import { DisputeModal } from './pages/Disputes/Disputes.ID'
import { MerchantAccounts } from './pages/Merchant/Accounts/Accounts'
import { MerchantAPIKey } from './pages/Merchant/Accounts/Accounts.ID.ApiKey.New'
import { MerchantAccountContracts } from './pages/Merchant/Accounts/Accounts.ID.Contract'
import { MerchantAccountContractsPreview } from './pages/Merchant/Accounts/Accounts.ID.ContractPreview'
import { MerchantAccountAddANewMember } from './pages/Merchant/Accounts/Accounts.ID.Members.New'
import { MerchantAccountNew } from './pages/Merchant/Accounts/Accounts.New'
import { MerchantApplication } from './pages/Merchant/Application/Application'
import { MerchantApplicationWebsites } from './pages/Merchant/Application/Application.Websites'
import { MerchantDisputes } from './pages/Merchant/Disputes/Disputes'
import { Merchant } from './pages/Merchant/Merchant'
import { MerchantApplicationConflicts } from './pages/Merchant/MerchantApplicationConflicts'
import { MerchantRedirect } from './pages/Merchant/MerchantRedirect'
import { MerchantReferralModal } from './pages/Merchant/MerchantReferralModal'
import { MerchantRouter } from './pages/Merchant/MerchantRouter'
import { MerchantTagsManagerEdit } from './pages/Merchant/MerchantTagsManagerEdit'
import { MerchantTagsManagerList } from './pages/Merchant/MerchantTagsManagerList'
import { MerchantTagsManagerRouter } from './pages/Merchant/MerchantTagsManagerRouter'
import { MerchantMore } from './pages/Merchant/More/More'
import { Report } from './pages/Merchant/More/ODD/Report'
import { Reports } from './pages/Merchant/More/ODD/Reports'
import { RiskCard } from './pages/Merchant/More/Risk/RiskCard'
import { MoreAddNotification } from './pages/Merchant/More/Subscriptions/More.AddNotification'
import { MoreNotificationsManagement } from './pages/Merchant/More/Subscriptions/More.Notifications'
import { MerchantSettlements } from './pages/Merchant/Settlements/Settlements'
import { MerchantSummary } from './pages/Merchant/Summary/Summary'
import { MerchantAddCollaborator } from './pages/Merchant/Summary/SummaryAddCollaborator'
import { MerchantExploreRelated } from './pages/Merchant/Summary/SummaryExploreRelated'
import { MerchantExploreTimeline } from './pages/Merchant/Summary/SummaryExploreTimeline'
import { MerchantTasks } from './pages/Merchant/Tasks/Tasks'
import { MerchantTransactions } from './pages/Merchant/Transactions/Transactions'
import { Manage } from './pages/More/More'
import { ManageContractTemplates } from './pages/More/More.ContractTemplates'
import { ManageNewContractTemplate } from './pages/More/More.ContractTemplates.Edit'
import { ManageExports } from './pages/More/More.Exports'
import { Gateways } from './pages/More/More.Gateways'
import { NewGateway } from './pages/More/More.Gateways.New'
import { ReconcilePosting } from './pages/More/More.ReconcilePosting'
import { ReconcileVoucher } from './pages/More/More.ReconcileVoucher'
import { UnreconciledPostings } from './pages/More/More.UnreconciledPostings'
import { UnreconciledVouchers } from './pages/More/More.UnreconciledVouchers'
import { ManageVouchers } from './pages/More/More.Vouchers'
import { ManageNewVoucher } from './pages/More/More.Vouchers.New'
import { ODDTasks } from './pages/ODDTasks/ODDTasks'
import { PreviewFile } from './pages/PreviewFile'
import { Settlements } from './pages/Settlements/Settlements'
import { SettlementModal } from './pages/Settlements/Settlements.ID'
import { Tasks } from './pages/Tasks/Tasks'
import { Transactions } from './pages/Transactions/Transactions'
import { TransactionModal } from './pages/Transactions/Transactions.ID'
import { ErrorPage } from './error-page'
import { Partners, Partner, PartnerAdd, PartnerEdit, PartnerRemove } from '@/routes/more/partners'
import { IntegrationAdd, IntegrationEdit } from '@/routes/more/partners/integrations'
import { SigningKeyAdd, SigningKeyEdit } from '@/routes/more/partners/integrations/signing-keys'
import { Classification as RiskClassification } from './pages/Merchant/More/Risk/Classification'
import { Classification as RiskItem } from './pages/Merchant/More/Risk/RiskItem'

export const router = createBrowserRouter([
    {
        path: '/',
        Component: Main,
        errorElement: <ErrorPage />,
        children: [
            { path: 'preview-file/:id', Component: PreviewFile },
            { path: 'more/contract-templates/:id', Component: ManageNewContractTemplate },
            { path: 'more/gateways/:id', Component: NewGateway },
            { path: 'more/vouchers/new', Component: ManageNewVoucher },
            { path: 'more/posting/:id', Component: ReconcilePosting },
            { path: 'more/voucher/:id', Component: ReconcileVoucher },
            { path: 'more/partners/add', Component: PartnerAdd, action: PartnerAdd.action },
            {
                path: 'more/partners/:partnerId/integrations/:integrationId/signing-keys/add',
                Component: SigningKeyAdd,
                action: SigningKeyAdd.action
            },
            {
                path: 'more/partners/:partnerId/integrations/:integrationId/signing-keys/:signingKeyId/edit',
                Component: SigningKeyEdit,
                loader: SigningKeyEdit.loader,
                action: SigningKeyEdit.action
            },
            {
                path: 'more/partners/:id/edit',
                Component: PartnerEdit,
                loader: PartnerEdit.loader,
                action: PartnerEdit.action
            },
            {
                path: 'more/partners/:id/integrations/add',
                Component: IntegrationAdd,
                action: IntegrationAdd.action
            },
            {
                path: 'more/partners/:partnerId/integrations/:integrationId/edit',
                Component: IntegrationEdit,
                loader: IntegrationEdit.loader,
                action: IntegrationEdit.action
            },
            {
                path: 'more',
                Component: Manage,
                children: [
                    { path: 'gateways', Component: Gateways },
                    {
                        path: 'partners',
                        Component: Partners,
                        loader: Partners.loader,
                        children: [
                            {
                                path: ':id/delete',
                                action: PartnerRemove.action
                            }
                        ]
                    },
                    {
                        path: 'partners/:id',
                        Component: Partner,
                        loader: Partner.loader
                    },
                    { path: 'vouchers', Component: ManageVouchers },
                    { path: 'unreconciled-vouchers', Component: UnreconciledVouchers },
                    { path: 'unreconciled-postings', Component: UnreconciledPostings },
                    { path: 'exports', Component: ManageExports },
                    { path: 'contract-templates', Component: ManageContractTemplates },
                    { path: '', element: <Navigate to="partners" /> }
                ]
            },
            {
                path: 'merchant/:id',
                Component: MerchantRouter,
                children: [
                    { path: 'application/conflicts', Component: MerchantApplicationConflicts },
                    {
                        path: 'tags',
                        Component: MerchantTagsManagerRouter,
                        children: [
                            { path: ':tagId', Component: MerchantTagsManagerEdit },
                            { path: '', Component: MerchantTagsManagerList }
                        ]
                    },
                    { path: 'more/classifications/:classification_id', Component: RiskClassification },

                    { path: 'more/ongoing-due-diligence/reports/:report_id', Component: Report },
                    { path: 'more/new-notification', Component: MoreAddNotification },
                    { path: 'more/edit-notification/:notification_id', element: <MoreAddNotification isEditing /> },
                    { path: 'more/new-webhook', element: <MoreAddNotification forHooks /> },
                    { path: 'more/edit-webhook/:notification_id', element: <MoreAddNotification isEditing forHooks /> },
                    { path: 'more/add-referral', Component: MerchantReferralModal },
                    { path: 'accounts/new', Component: MerchantAccountNew },
                    { path: 'accounts/:account_id/settlements/:sole_mid', element: <Settlements fullPage /> },
                    { path: 'accounts/:account_id/disputes/:sole_mid', element: <Disputes fullPage /> },
                    { path: 'accounts/:account_id/transactions/:sole_mid', element: <Transactions fullPage /> },
                    { path: 'accounts/:accountId/api-key/:apiKeyId', Component: MerchantAPIKey },
                    { path: 'accounts/:accountId/members/new', Component: MerchantAccountAddANewMember },
                    {
                        path: 'accounts/:accountId/contracts/:contractId/preview',
                        Component: MerchantAccountContractsPreview
                    },
                    { path: 'summary/timeline', Component: MerchantExploreTimeline },
                    { path: 'summary/related', Component: MerchantExploreRelated },
                    { path: 'summary/add-collaborator', Component: MerchantAddCollaborator },
                    { path: 'disputes/:disputeId', Component: DisputeModal },
                    { path: 'settlements/:settlementId', Component: SettlementModal },
                    {
                        path: 'tasks/:id',
                        Component: AlertRouter,
                        children: [
                            { path: 'tags', Component: AlertTagsManager },
                            { path: '', element: <AlertModal type="task" /> }
                        ]
                    },
                    { path: 'transactions/:transactionId', Component: TransactionModal },
                    {
                        path: '',
                        Component: Merchant,
                        children: [
                            { path: 'summary', Component: MerchantSummary },
                            {
                                path: 'application/:sidebar/:sectionLabel/:fieldLabelOrID/:fieldLabel',
                                Component: MerchantApplication
                            },
                            {
                                path: 'application/:sidebar/:sectionLabel/:fieldLabelOrID',
                                Component: MerchantApplication
                            },
                            { path: 'application/websites', Component: MerchantApplicationWebsites },
                            { path: 'application', Component: MerchantApplication },
                            { path: 'accounts/:accountId/contracts/:contractId', Component: MerchantAccountContracts },
                            { path: 'accounts/:accountId/contracts', Component: MerchantAccountContracts },
                            { path: 'accounts/:accountId', Component: MerchantAccounts },
                            { path: 'accounts', Component: MerchantAccounts },
                            { path: 'more/risk/classifications/:classification_id', Component: RiskItem },

                            {
                                path: 'more',
                                Component: MerchantMore,
                                children: [
                                    {
                                        path: 'risk-tab',
                                        element: (
                                            <Flex column>
                                                <RiskCard />
                                            </Flex>
                                        )
                                    },
                                    { path: 'ongoing-due-diligence', Component: Reports },
                                    {
                                        path: 'subscriptions-tab',
                                        element: (
                                            <Flex column>
                                                <MoreNotificationsManagement />
                                                <Spacer height={20} />
                                                <MoreNotificationsManagement forHooks />
                                            </Flex>
                                        )
                                    },
                                    { path: '', element: <Navigate to="risk-tab" replace={true} /> }
                                ]
                            },
                            { path: 'tasks', Component: MerchantTasks },
                            { path: 'transactions', Component: MerchantTransactions },
                            { path: 'disputes', Component: MerchantDisputes },
                            { path: 'settlements', Component: MerchantSettlements },
                            { path: '', Component: MerchantRedirect }
                        ]
                    }
                ]
            },
            { path: 'applications', Component: Applications },
            {
                path: 'alerts/:id/*',
                Component: AlertRouter,
                children: [
                    { path: 'tags', Component: AlertTagsManager },
                    { path: '', element: <AlertModal type="alert" /> }
                ]
            },
            { path: 'alerts/*', Component: Alerts },
            { path: 'disputes/:disputeId', Component: DisputeModal },
            { path: 'disputes', Component: Disputes },
            {
                path: 'tasks/:id/*',
                Component: AlertRouter,
                children: [
                    { path: 'tags', Component: AlertTagsManager },
                    { path: '', element: <AlertModal type="task" /> }
                ]
            },
            { path: 'tasks', Component: Tasks },
            { path: 'odd-tasks/:filter', Component: ODDTasks },
            { path: 'transactions/:transactionId', Component: TransactionModal },
            { path: 'transactions', Component: Transactions },
            { path: 'settlements/:settlementId', Component: SettlementModal },
            { path: 'settlements', Component: Settlements },
            {
                path: 'calls',
                Component: Calls,
                children: [
                    { path: 'active-calls', Component: ActiveCalls },
                    { path: 'queues', Component: Queues },
                    { path: ':id', Component: Call }
                ]
            },
            { path: '', element: <Navigate to="/applications" /> }
        ]
    }
])
