import { Text } from '@/components/general/text'
import { Flex } from '@/components/layout/flex'
import { Spacer } from '@/components/layout/spacer'
import { LoaderView } from '@/components/loaders/loader'
import {
    FormProvider,
    ListVoucherSection,
    LoadHigherAmountsButton,
    ReconciliationModal,
    SinglePostingSection,
    useReconciliationParams
} from '@/features/reconciliation'
import { useGetAccounts, useGetPosting, useGetVouchers } from '@/services/reconciliation'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ReconcilePosting: React.FC = () => {
    const { id } = useReconciliationParams()
    const { data: postingData, isLoading: postingLoading, isError: postingError } = useGetPosting(id)
    const { data: vouchersData, isLoading: vouchersLoading, isError: vouchersError } = useGetVouchers()
    const { data: accountsData, isLoading: accountsLoading, isError: accountsError } = useGetAccounts()
    const [loadHigherAmounts, setLoadHigherAmounts] = useState(false)

    const navigate = useNavigate()

    const handleClick = useCallback(() => {
        setLoadHigherAmounts((t) => !t)
    }, [setLoadHigherAmounts])

    useEffect(() => {
        if (postingError || vouchersError || accountsError) {
            navigate(-1)
        }
    }, [navigate, accountsError, postingError, vouchersError])

    if (postingLoading || vouchersLoading || accountsLoading) {
        return <LoaderView overBackground="front.background" type="l" minHeight="200" />
    }

    if (!postingData || !vouchersData || !accountsData) {
        return <Text>No data available</Text>
    }

    return (
        <FormProvider data={'posting'}>
            <ReconciliationModal type="posting">
                <Flex column>
                    <SinglePostingSection posting={postingData} accounts={accountsData.items} />
                    <LoadHigherAmountsButton
                        data={postingData}
                        loadHigherAmounts={loadHigherAmounts}
                        onClick={handleClick}
                    />
                    {!postingData.reconciled && (
                        <>
                            <Spacer width={20} />
                            <ListVoucherSection
                                vouchers={vouchersData.items
                                    .filter((v) =>
                                        loadHigherAmounts
                                            ? v.currency === postingData.currency
                                            : v.currency === postingData.currency && v.amount <= postingData.amount
                                    )
                                    .sort((a, b) => {
                                        return b.amount - a.amount
                                    })}
                                accounts={accountsData.items}
                            />
                        </>
                    )}
                </Flex>
            </ReconciliationModal>
        </FormProvider>
    )
}
