import { VariantProps } from 'class-variance-authority'
import React from 'react'
import { cn } from '../utils'
import { styles } from './styles'

interface TextProps extends VariantProps<typeof styles> {
    testid?: string
    children: React.ReactNode
}

export const Text: React.FC<TextProps> = (props) => {
    const { testid, children, family, size, color, shade, weight, style, align, lineHeight } = props

    const classnames = cn(styles({ family, size, color, weight, style, align, lineHeight, shade }))

    return (
        <p data-cy={testid} className={classnames}>
            {children}
        </p>
    )
}
