import React from 'react'
import { cn } from '../utils'
import { Styles, styles } from './styles'

interface InputProps extends Styles {
    testid?: string
    name?: string
    placeholder?: string
    defaultValue?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { testid, name, placeholder, defaultValue, onChange, onBlur } = props

    const classnames = cn(styles())

    return (
        <input
            data-cy={testid}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            className={classnames}
            ref={ref}
        />
    )
})

Input.displayName = 'Input'

export { Input }
