import React from 'react'
import { Table, TableColHeader, TableRow } from '@/components/tables/table'
import { CardInset } from '@/components/cards/cardInset'
import { Card } from '@/components/cards/card'
import { Spacer } from '@/components/layout/spacer'

interface VouchersTableProps {
    isLoading: boolean
    rows: TableRow[]
    cols: TableColHeader[]
}

export const VouchersTable: React.FC<VouchersTableProps> = (props) => {
    const { isLoading, rows, cols } = props

    return (
        <>
            <Card>
                <CardInset>
                    <Table
                        displayLoader={isLoading}
                        loaderRows={20}
                        rows={rows}
                        cols={cols}
                        background="front.background"
                        columnLayout="240px 100px 100px 2fr 2fr 0.5fr 1fr min-content 80px"
                        emptyText="No unreconciled vouchers found."
                    />
                </CardInset>
            </Card>
            <Spacer width={20} />
        </>
    )
}
