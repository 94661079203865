import { Box, Button, ChevronRightIcon, Collapsible, Flex, Text } from '@/design-system'
import { Partner } from '@/services/merchant-api'
import React from 'react'

interface IntegrationHeaderProps {
    children?: React.ReactNode
    partners: Partner[]
    onEditClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const IntegrationHeader: React.FC<IntegrationHeaderProps> = (props) => {
    const { partners, onEditClick } = props

    const technicalPartner = partners.find((partner) => partner.technical)
    const supportPartner = partners.find((partner) => partner.support)

    const technicalPartnerName = technicalPartner?.name ? technicalPartner.name : '-'
    const supportPartnerName = supportPartner?.name ? supportPartner.name : '-'

    return (
        <Flex justify="between" align="center">
            <Flex gap="8">
                <Flex gap="1" direction="column">
                    <Text weight="medium">{technicalPartnerName}</Text>
                    <Text color="neutral">Technical partner</Text>
                </Flex>
                <Flex align="center">
                    <ChevronRightIcon color="neutral" size="3.5" />
                </Flex>
                <Flex gap="1" direction="column">
                    <Text weight="medium">{supportPartnerName}</Text>
                    <Text color="neutral">Support partner</Text>
                </Flex>
            </Flex>
            <Flex align="center" gap="1">
                <Button testid="integration-edit-button" variant="link" onClick={onEditClick} asChild>
                    <span>Edit</span>
                </Button>
                <Box mb="1">
                    <Collapsible.Chevron />
                </Box>
            </Flex>
        </Flex>
    )
}
