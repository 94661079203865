import { Flex, Text, Separator, Box } from '@/design-system'

interface ApplicationSectionHeadingProps {
    children: React.ReactNode
}

const ApplicationSectionHeading: React.FC<ApplicationSectionHeadingProps> = (props) => {
    const { children } = props
    return (
        <Box mb="1">
            <Flex gap="3" direction="column">
                <Text weight="medium" shade="500">
                    {children}
                </Text>
                <Separator color="neutral" shade="200" />
            </Flex>
        </Box>
    )
}

export { ApplicationSectionHeading }
