import { Card } from '@/components/cards/card'
import { CardInset } from '@/components/cards/cardInset'
import { Table } from '@/components/tables/table'
import { TableBubble } from '@/components/layout/tableBubble'
import { Text } from '@/components/general/text'
import { Account, Voucher } from '@/services/reconciliation'
import React, { useMemo } from 'react'
import { AccountDetails, Date } from '../common'
import { numberWithCurrency } from '@/utils'
import { getAccount } from '../../helpers'
import { Flex } from '@/components/layout/flex'
import { UnreconcileAction } from './unreconcile-action'
import { Spacer } from '@/components/layout/spacer'
import { getVoucherCols } from '../vouchers/vouchers-table/helpers'

interface SingleVoucherSectionProps {
    voucher: Voucher
    accounts: Account[]
}

export const SingleVoucherSection: React.FC<SingleVoucherSectionProps> = (props) => {
    const { voucher, accounts } = props
    
    const cols = getVoucherCols()

    const rows = useMemo(() => {
        return [{
            type: 'normal' as const,
            key: voucher.id,
            noAction: true,
            items: [
                {
                    node: accounts && <AccountDetails account={getAccount(voucher.account_id, accounts)} />
                },
                {
                    node: <Date>{voucher.booking_date}</Date>
                },
                {
                    node: <Date>{voucher.payment_date}</Date>
                },
                {
                    node: <Text>{voucher.descriptor}</Text>
                },
                {
                    node: <Text>{voucher.reference}</Text>
                },
                {
                    node: <Text>{voucher.posting_type}</Text>
                },
                {
                    node: <Text>{voucher.voucher_type}</Text>
                },
                {
                    node: (
                        <TableBubble type="important">
                            {numberWithCurrency(voucher.currency, `${voucher.amount}`)} {voucher.currency}
                        </TableBubble>
                    )
                }
            ]
        }]
    }, [voucher])

    return (
        <Card>
            <CardInset>
                <Table
                    loaderRows={1}
                    rows={rows}
                    cols={cols}
                    background="front.background"
                    noHighlights
                    columnLayout="min-content 100px 100px 2fr 2fr 0.5fr 1fr min-content"
                    emptyText="No voucher found."
                />
                {voucher.reconciled && (
                    <>
                        <Spacer width={20} />
                        <Flex align="flex-end" column>
                            <UnreconcileAction type="voucher" />
                        </Flex>
                    </>
                )}
            </CardInset>
        </Card>
    )
}
