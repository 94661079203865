import React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from '../utils'
import { styles } from './styles'

interface PopoverProps {
    children: React.ReactNode
    defaultOpen?: boolean
    open?: boolean
    onOpenChange?: (open: boolean) => void
}

const Popover = (props: PopoverProps) => {
    const { children, defaultOpen, open, onOpenChange } = props

    return (
        <PopoverPrimitive.Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
            {children}
        </PopoverPrimitive.Root>
    )
}

interface TriggerProps {
    testid?: string
    children: React.ReactNode
    asChild?: boolean
}

const Trigger = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Trigger>, TriggerProps>((props, ref) => {
    const { testid, children, asChild } = props

    return (
        <PopoverPrimitive.Trigger data-cy={testid} asChild={asChild} ref={ref}>
            {children}
        </PopoverPrimitive.Trigger>
    )
})

Trigger.displayName = 'Trigger'

interface ContentProps {
    children: React.ReactNode
    align?: 'start' | 'center' | 'end'
    side?: 'top' | 'right' | 'bottom' | 'left'
}

const Content = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, ContentProps>((props, ref) => {
    const { children, align, side } = props

    const classnames = cn(styles.content())

    return (
        <PopoverPrimitive.Portal>
            <PopoverPrimitive.Content ref={ref} align={align} side={side} sideOffset={4} className={classnames}>
                {children}
            </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
    )
})

Content.displayName = 'Content'

Popover.Trigger = Trigger
Popover.Content = Content

export { Popover }
