import React from 'react'
import { cn } from '../utils'
import { Slot } from '@radix-ui/react-slot'
import { Styles, styles } from './styles'

interface ButtonProps extends Styles {
    testid?: string
    children: React.ReactNode
    asChild?: boolean
    type?: 'button' | 'submit' | 'reset'
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const Button: React.FC<ButtonProps> = (props) => {
    const { testid, children, asChild, type = 'button', onClick, variant, size, color, width } = props

    const classnames = cn(styles({ variant, size, color, width }))
    const Component = asChild ? Slot : 'button'

    return (
        <Component data-cy={testid} className={classnames} type={type} onClick={onClick}>
            {children}
        </Component>
    )
}
