import { MonitoredChange, MonitoredFetchedApplicationData } from '../../../../../../../store/tasksCompanyChanges/types'
import { uppercaseFirstLetter } from '../../../../../../../utils'
import { Text } from '../../../../../../../components/general/text'
import { TableRowType } from '../../../../../../../components/tables/table'
import { Flex } from '../../../../../../../components/layout/flex'
import { Spacer } from '../../../../../../../components/layout/spacer'
import { camelCase, isNumber, isString } from 'lodash'
import { findKeyValueInObject } from '../../../../../../../utils/objects'
import { MuteButton } from '../components/MuteButton'
import { ApplyButton } from '../components/ApplyButton'
import { Status } from '../components/Status'

export const useRows = (
    taskId: string,
    changes: MonitoredChange[],
    applicationData: MonitoredFetchedApplicationData
) => {
    const rows = changes.map((change) => {
        const pathSplit = change.field.split('/').filter((s) => s)
        const section = pathSplit[0]
        const field = pathSplit[1]
        const sectionCamelCase = camelCase(section)
        const fieldCamelCase = camelCase(pathSplit[1])

        const before = change.before
        const after = isString(change.after) || isNumber(change.after) ? change.after.toString() : null

        const subsectionObject = findKeyValueInObject(applicationData, 'id', change.subsectionId)

        const applicationValue = subsectionObject ? subsectionObject[fieldCamelCase] : null

        // Mute / Unmute
        const mutedValues = applicationData?.muted[sectionCamelCase]?.[fieldCamelCase]
            ? applicationData?.muted[sectionCamelCase]?.[fieldCamelCase]
            : []

        const isMuted = after ? (mutedValues || []).includes(after) : false

        let status = 'in-progress'
        if (isMuted) {
            status = 'muted'
        } else if (after === null) {
            status = 'unknown'
        } else if (applicationValue !== null && applicationValue === after) {
            status = 'complete'
        }

        const applyIsDisabled = status === 'complete' || status === 'unknown'
        const muteDisabled = status === 'unknown'

        return {
            type: 'normal' as TableRowType,
            noAction: true,
            key: '1',
            items: [
                { node: <Status status={status} /> },
                { node: <Text>{uppercaseFirstLetter(section)}</Text> },
                { node: <Text>{uppercaseFirstLetter(field)}</Text> },
                { node: <Text>{before ? before : '-'}</Text> },
                { node: <Text>{after ? after : '-'}</Text> },
                {
                    node: (
                        <Flex>
                            <MuteButton
                                taskId={taskId}
                                section={section}
                                field={field}
                                after={after}
                                applicationData={applicationData}
                                disabled={muteDisabled}
                            />
                            <Spacer width={12} height={1} />
                            <ApplyButton
                                taskId={taskId}
                                field={field}
                                after={after}
                                subsectionId={change.subsectionId}
                                applicationData={applicationData}
                                disabled={applyIsDisabled}
                            />
                        </Flex>
                    )
                }
            ]
        }
    })

    return rows
}
