import { createActionCreator } from 'deox'

import { WatcherID } from '../watcher/types'
import {
    MerchantAccountContract,
    MerchantAccountDraftContract,
    MerchantAccountWithContract,
    MerchantAccountMember,
    MerchantAccount,
    LocallyStoredDraftContract
} from './types'

const Prefix = 'MERCHANT_ACCOUNTS'

export const MerchantAccountsActions = {
    UPDATE_DRAFT: createActionCreator(
        `${Prefix}/UPDATE_DRAFT`,
        (resolve) =>
            (
                watcherId: WatcherID,
                accountId: string,
                draft: LocallyStoredDraftContract,
                changes: {
                    [key: string]: {
                        from: string
                        to: string
                    }
                }
            ) =>
                resolve({ watcherId, draft, accountId, changes })
    ),
    CREATE_API_KEY: createActionCreator(
        `${Prefix}/CREATE_API_KEY`,
        (resolve) => (accountId: string, watcherId: WatcherID, scope?: string, expiresAt?: string) =>
            resolve({ accountId, watcherId, expiresAt, scope })
    ),
    UPDATE_API_KEY: createActionCreator(
        `${Prefix}/UPDATE_API_KEY`,
        (resolve) =>
            (
                accountId: string,
                watcherId: WatcherID,
                link: string,
                scope: string,
                enabled: boolean,
                expiresAt?: string
            ) =>
                resolve({ accountId, watcherId, link, enabled, expiresAt, scope })
    ),
    STORE_API_KEY: createActionCreator(
        `${Prefix}/STORE_API_KEY`,
        (resolve) => (accountId: string, data: any) => resolve({ accountId, data })
    ),
    FETCH: createActionCreator(`${Prefix}/FETCH`, (resolve) => (merchantId: string) => resolve({ merchantId })),
    STORE_CONTRACT_VERSION: createActionCreator(
        `${Prefix}/STORE_CONTRACT_VERSION`,
        (resolve) => (accountId: string, contract: MerchantAccountContract) => resolve({ accountId, contract })
    ),
    FETCH_ACCOUNT: createActionCreator(
        `${Prefix}/FETCH_ACCOUNT`,
        (resolve) => (accountId: string, watcherId?: string, watcherMessage?: string, forceReload?: boolean) =>
            resolve({ accountId, watcherId, watcherMessage, forceReload })
    ),
    REFETCH_UPDATED_DRAFT_CONTRACT: createActionCreator(
        `${Prefix}/REFETCH_UPDATED_DRAFT_CONTRACT`,
        (resolve) => (accountId: string, draftLink: string, skipLoadingStatusChange?: boolean) =>
            resolve({ accountId, draftLink, skipLoadingStatusChange })
    ),
    STAMP_CONTRACT: createActionCreator(
        `${Prefix}/STAMP_CONTRACT`,
        (resolve) => (watcherId: string, accountId: string, contractId: string, link: string) =>
            resolve({ watcherId, accountId, contractId, link })
    ),
    STAMP_DRAFT: createActionCreator(
        `${Prefix}/STAMP_DRAFT`,
        (resolve) =>
            (
                watcherId: string,
                accountId: string,
                draftId: string,
                type: 'refuse' | 'approve' | 'ready' | 'ready-then-approve'
            ) =>
                resolve({ watcherId, accountId, draftId, type })
    ),
    CREATE_ACCOUNT: createActionCreator(
        `${Prefix}/CREATE_ACCOUNT`,
        (resolve) => (watcherId: string, account: MerchantAccount, applicationId: string) =>
            resolve({ watcherId, account, applicationId })
    ),
    UPDATE_ACCOUNT: createActionCreator(
        `${Prefix}/UPDATE_ACCOUNT`,
        (resolve) => (watcherId: string, account: MerchantAccount, link: string) =>
            resolve({ watcherId, account, link })
    ),
    STAMP_ACCOUNT: createActionCreator(
        `${Prefix}/STAMP_ACCOUNT`,
        (resolve) => (watcherId: string, accountId: string, link: string, successMessage?: string) =>
            resolve({ watcherId, accountId, link, successMessage })
    ),
    STORE_DRAFT: createActionCreator(
        `${Prefix}/STORE_DRAFT`,
        (resolve) => (accountId: string, draft: MerchantAccountDraftContract) => resolve({ accountId, draft })
    ),
    FETCH_CONTRACT_HISTORY: createActionCreator(
        `${Prefix}/FETCH_CONTRACT_HISTORY`,
        (resolve) => (accountId: string, contractLink: string) => resolve({ accountId, contractLink })
    ),
    STORE_CONTRACT_HISTORY: createActionCreator(
        `${Prefix}/STORE_CONTRACT_HISTORY`,
        (resolve) => (accountId: string, contracts: MerchantAccountContract[]) => resolve({ accountId, contracts })
    ),
    STORE_ACCOUNT_WITH_CONTRACT: createActionCreator(
        `${Prefix}/STORE_ACCOUNT_WITH_CONTRACT`,
        (resolve) => (applicationId: string, accountWithContract: MerchantAccountWithContract) =>
            resolve({ applicationId, accountWithContract })
    ),
    STORE_ACCOUNTS_WITHOUT_CONTRACTS: createActionCreator(
        `${Prefix}/STORE_ACCOUNTS_WITHOUT_CONTRACTS`,
        (resolve) => (applicationId: string, accountsWithContracts: MerchantAccountWithContract[]) =>
            resolve({ applicationId, accountsWithContracts })
    ),
    INVITE_MEMBER: createActionCreator(
        `${Prefix}/INVITE_MEMBER`,
        (resolve) => (watcherId: string, accountId: string, email: string, invitationMessage: string, link: string) =>
            resolve({ watcherId, email, invitationMessage, link, accountId })
    ),
    REMOVE_MEMBER: createActionCreator(
        `${Prefix}/REMOVE_MEMBER`,
        (resolve) => (watcherId: string, accountId: string, id: string, link: string) =>
            resolve({ watcherId, id, accountId, link })
    ),
    STORE_MEMBER: createActionCreator(
        `${Prefix}/STORE_MEMBER`,
        (resolve) => (accountId: string, memberId: string, data?: MerchantAccountMember) =>
            resolve({ accountId, memberId, data })
    ),
    CLEAR: createActionCreator(`${Prefix}/CLEAR`, (resolve) => () => resolve())
}
