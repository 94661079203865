import { Checkbox } from '@/components/forms/checkbox'
import { Controller, useFormContext } from 'react-hook-form'

interface ItemCheckBoxProps {
    name: string
    value: string
}

export const ItemCheckBox: React.FC<ItemCheckBoxProps> = (props) => {
    const { name, value: passedValue } = props
    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name={name}
            render={({field: {value, onChange}}) => (
                <Checkbox
                    label=""
                    onChange={(_, checked) => {
                        const valueCopy = [...value]
                        if (checked) {
                            valueCopy.push(passedValue)
                        } else {
                            const idx = valueCopy.findIndex((option) => option === passedValue)
                            valueCopy.splice(idx, 1)
                        }
                        onChange(valueCopy)
                    }}
                />
            )}
        />
    )
}
