import { VariantProps, cva } from 'class-variance-authority'

export type TriggerStyles = VariantProps<typeof styles.trigger>
export type ContentStyles = VariantProps<typeof styles.content>
export type ChevronStyles = VariantProps<typeof styles.chevron>

export const styles = {
    trigger: cva('group w-full'),
    content: cva(),
    chevron: cva('text-neutral-500 h-5 w-5 group-data-[state=open]:rotate-180', {
        variants: {
            color: {
                white: 'text-white',
                black: 'text-black',
                primary: 'text-primary-700',
                neutral: 'text-neutral-700',
                success: 'text-success-700',
                warning: 'text-warning-700',
                danger: 'text-danger-700',
                notice: 'text-notice-700'
            }
        }
    })
}
