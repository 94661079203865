import { api } from '../api'
import { ReconcilePostingData } from '../types/reconciliation'
import { Posting, Postings } from '../types/postings'

export const postingsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPostings: build.query<Postings, void>({
            query: () => `/postings`,
            providesTags: ['postings']
        }),
        getPosting: build.query<Posting, string>({
            query: (postingId: string) => `/postings/${postingId}`,
            providesTags: ['posting']
        }),
        reconcilePosting: build.mutation<Posting, { postingId: string, data: ReconcilePostingData }>({
            query: ({ postingId, data }) => ({
                url: `/postings/${postingId}/reconcile`,
                method: 'PUT',
                body: {
                    ...data
                }
            }),
            invalidatesTags: ['posting', 'postings', 'vouchers']
        }),
        unreconcilePosting: build.mutation<Posting, { postingId: string }>({
            query: ({ postingId }) => ({ url: `/postings/${postingId}/unreconcile`, method: 'PUT'}),
            invalidatesTags: ['posting', 'postings', 'vouchers']
        })
    })
})

export const {
    useGetPostingsQuery: useGetPostings,
    useGetPostingQuery: useGetPosting,
    useReconcilePostingMutation: useReconcilePosting,
    useUnreconcilePostingMutation: useUnreconcilePosting
} = postingsApi
