import { HoverCard, Link, Text } from '@/design-system'
import _ from 'lodash'
import React from 'react'

interface HoverCardVerificationKeyProps {
    verificationKey: string
}

export const HoverCardVerificationKey: React.FC<HoverCardVerificationKeyProps> = (props) => {
    const { verificationKey } = props

    const truncatedVerificationKey = _.truncate(verificationKey, { length: 32, omission: '...' })

    return (
        <HoverCard>
            <HoverCard.Trigger>
                <Link asChild>
                    <span>{truncatedVerificationKey}</span>
                </Link>
            </HoverCard.Trigger>
            <HoverCard.Content>
                <pre>
                    <Text>{verificationKey}</Text>
                </pre>
            </HoverCard.Content>
        </HoverCard>
    )
}
