import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LinkButton } from '../../../components/buttons/linkButton'
import { Card } from '../../../components/cards/card'
import { CardInset } from '../../../components/cards/cardInset'
import { Text } from '../../../components/general/text'
import { Flex } from '../../../components/layout/flex'
import { Table } from '../../../components/tables/table'
import { RootState } from '@/store'
import { uppercaseFirstLetter } from '../../../utils'
import moment from 'moment'
import { DateFormats } from '../../../utils/dateUtils'

export const MerchantAccountAPIKeys: React.FC<{
    merchantId: string
    accountId: string
    formRef: any
    errors?: any
    data: any
    touched: any
}> = ({ accountId, errors, merchantId, formRef, data, touched }) => {
    const account = useSelector((state: RootState) => state.merchantAccounts.at[accountId])

    const cols = useMemo(() => {
        return [
            {
                text: 'Key',
                loaderSize: {
                    min: 50,
                    max: 50
                }
            },
            {
                text: 'Expire on',
                loaderSize: {
                    min: 40,
                    max: 40
                }
            },
            {
                text: 'Scope',
                loaderSize: {
                    min: 50,
                    max: 150
                }
            },
            {
                text: 'Enabled',
                loaderSize: {
                    min: 15,
                    max: 15
                }
            },
            {
                text: '',
                alignRight: true,
                loaderSize: {
                    min: 25,
                    max: 25
                }
            }
        ]
    }, [])

    const rows = useMemo(() => {
        const keys = JSON.parse(JSON.stringify(account?.apiKeys || []))

        keys
            ?.sort((a: any, b: any) => {
                if (!a?.enabled) return 1
                if (!b?.enabled) return -1
            })
            .sort((a: any, b: any) => {
                if (!a?.expiresAt) return 1
                if (!b?.expiresAt) return -1

                return moment(a.expiresAt).isBefore(moment(b.expiresAt)) ? 1 : -1
            })

        return keys?.map((c: any) => {
            let date = c?.expiresAt ? moment(c.expiresAt).format(DateFormats.day(moment(c.expiresAt), true)) : '-'
            let isSubtle = false
            if (moment(c.expiresAt).isBefore(moment(new Date()), 'D')) {
                date = `Expired (${date})`
                isSubtle = true
            }
            if (!c.expiresAt) isSubtle = true
            return {
                type: 'normal' as const,
                key: c.token,
                noAction: true,
                items: [
                    {
                        node: <Text bold>{c.token}</Text>
                    },
                    {
                        node: (
                            <Text oneLine bold={!isSubtle} color={isSubtle ? 'front.text.subtlerI' : undefined}>
                                {date}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text color={isSubtle ? 'front.text.subtlerI' : undefined}>
                                {uppercaseFirstLetter(c.scope)}
                            </Text>
                        )
                    },
                    {
                        node: (
                            <Text color={isSubtle ? 'front.text.subtlerI' : undefined}>{c.enabled ? 'Yes' : 'No'}</Text>
                        )
                    },
                    {
                        node: (
                            <LinkButton
                                to={`/merchant/${merchantId}/accounts/${account.id}/api-key/${encodeURI(c.token)}`}
                            >
                                Edit
                            </LinkButton>
                        )
                    }
                ]
            }
        })
    }, [account, merchantId])

    const rightSideMemo = useMemo(() => {
        if (account.metadata.state !== 'live') return null
        return (
            <Flex grow justify="flex-end">
                <LinkButton noShrink cy="new-api-key" to={`/merchant/${merchantId}/accounts/${accountId}/api-key/new`}>
                    New API Key
                </LinkButton>
            </Flex>
        )
    }, [merchantId, account, accountId])

    return (
        <Card title="API Keys" rightSideMemo={rightSideMemo}>
            <CardInset>
                <Table
                    rows={rows}
                    cols={cols}
                    overrideText={
                        account?.metadata?.state === 'live'
                            ? 'No API Keys found.'
                            : 'No keys found, new API keys can only be created on LIVE accounts.'
                    }
                    loaderRows={5}
                    displayLoader={!account.extraDetailsLoaded}
                    columnLayout="2fr min-content auto 1fr min-content"
                    background="front.background"
                />
            </CardInset>
        </Card>
    )
}
